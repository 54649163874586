import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PageService } from '../../core/page.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: any = {};
  processing = true;
  settings: any;

  constructor(
    public formBuilder: FormBuilder,
    public pageService: PageService
  ) {
    this.settings = this.pageService.global.settings;
    this.form = this.formBuilder.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
      role: [this.settings.roles.administrators.administrator, Validators.required]
    });

    if(this.pageService.global.checkUser()) this.pageService.navigateRoute("/users");
  }

  ngOnInit() {

  }

  login(item) {
    const endPoint = this.settings.endPoints.administrators;
    const method = this.settings.endPointsMethods.administrators.login;

    this.pageService.httpPost(item, method, endPoint).then( (res) => {
      const user = res.data;
      this.pageService.global.saveUser(user);
      this.pageService.showSuccess('Bienvenido!');
    })
    .catch( (error) => { 
      this.pageService.showError(error);
    });
    
  }

}
