<div class="container">
  <nav aria-label="breadcrumb" class="mt-2">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/administrators']" routerLinkActive="router-link-active">Administradores</a></li>
      <li class="breadcrumb-item active" aria-current="page">Cambiar contraseña</li>
    </ol>
  </nav>

  <form [formGroup]="form" (ngSubmit)="save()" *ngIf="!processing">
    <div class="row">
      <div class="col-6">
        <div class="form-group mb-3">
          <label>Contraseña</label>
          <input type="password" class="form-control" formControlName="password">
        </div>
        <div class="form-group mb-3">
          <label>Contraseña nueva</label>
          <input type="password" class="form-control" formControlName="passwordNew">
        </div>
        <div class="form-group mb-3">
          <label>Repetir contraseña nueva</label>
          <input type="password" class="form-control" formControlName="passwordNewVerify">
        </div>
      </div>
    </div>

    <hr>
    <div class="form-row">
      <div class="col-6">
        <button class="btn btn-primary mb-4 float-right" block type="submit" [disabled]="form.invalid">
          <i class="fa fa-check" aria-hidden="true"></i> GUARDAR
        </button>
      </div>
    </div>

  </form>
</div>