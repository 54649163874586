import { Component } from '@angular/core';
import { ItemsComponent } from '../../core/items.component';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['../../core/items.component.scss']
})
export class SponsorsComponent extends ItemsComponent {

  name: string;
  picture: string;
  creating = false;
  disabled = false;

  handleEnable(sponsor) {
    sponsor.enabled = !sponsor.enabled;
    const enabledAction = (sponsor.enabled ? 'habilitado' : 'deshabilitado');

    let data = {
      id: sponsor.id,
      enabled: sponsor.enabled
    };
    this.pageService.httpUpdate(data)
      .then(res => {
        this.pageService.showSuccess('El sponsor ' + sponsor.name + ' ha sido ' + enabledAction + ' correctamente!');
      })
      .catch(err => this.pageService.showError(err));
  }

  openModal(content, item?, disabled?) {
    this.disabled = disabled ? true : false;
    
    if (item) {
      this.itemSelected = item;
      this.creating = false;
      this.name = this.itemSelected.name;
      this.picture = this.itemSelected.picture;
    } else {
      this.itemSelected = {};
      this.creating = true;
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.clearVariables();
    }, (reason) => {
      this.closeResult = `Dismissed`;
      this.clearVariables();
    });
  }
  
  closeModal() {
    this.modalService.dismissAll();
  }

  clearVariables() {
    this.name = undefined;
    this.picture = undefined;
  }
  
  saveItemModal() {
    this.itemSelected.name = this.name;
    this.itemSelected.picture = this.picture;

    if (this.creating) {
      this.pageService.httpCreate(this.itemSelected).then(res => {
        this.pageService.showSuccess('Creado con éxito');
        this.closeModal();
        this.getItems();
      }).catch(e => this.pageService.showError(e));
    } else {
      this.update(this.itemSelected, true);
      this.closeModal();
    }
  }

  modalSaveDisabled() {
    if ( !this.name || this.name == '' || !this.picture || this.picture == '' ) return true;

    return false;
  }

  changeImage() {
    this.pageService.showImageUpload()
      .then((resp: any) => {
        this.picture = resp.data.file;
      })
      .catch((err) => { 
        this.pageService.showError(err);
      });
  }

  getFilters() {
    let _filters = {};

    return _filters;
  }

  getFiltersSearch(textSearch) {

    let filtersSearch: any = {
      "$or": [
        { "name": { "$regex": textSearch, "$options": "i" } },
      ]
    };

    return filtersSearch;
  }

  changeTrend(item) {
      item.trend = !item.trend;
      this.pageService.httpUpdate(item, this.pageService.global.settings.endPoints.categories)
      .then(() => this.getItems())
      .catch((err) => {
        this.pageService.showError(err);
      })
  }
}
