import { Component } from '@angular/core';
import { ItemComponent } from '../../core/item.component';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['../../core/item.component.scss']
})

export class SponsorComponent extends ItemComponent {
  
  getFormNew(){
    return this.formBuilder.group({
     name: [null, Validators.required],
     picture: [null, Validators.required]
    })
  }

  getFormEdit(item){
    return this.formBuilder.group({
      id: [item.id],
      name: [item.name, Validators.required],
      picture: [item.picture, Validators.required]
    })
  }

  changeImage(){
    this.pageService.showImageUpload()
      .then((resp: any) => {
        this.form.patchValue({ picture: resp.data.file });
      })
      .catch((error: any) => { });
  }

  removePicture(){
    this.form.patchValue({ picture: undefined });
  }
}
