import { Component } from '@angular/core';
import { ItemsComponent } from '../../core/items.component';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['../../core/items.component.scss']
})
export class GroupsComponent extends ItemsComponent {
  
  textSearchCountries:any = '';
  textSearchProvinces:any = '';

  getEndPointLoad(){
    return this.settings.endPoints.groups + this.settings.endPointsMethods.groups.getGroups;
  }

  getPopulates() {
    return ["user","category"];
  }

  getFiltersSearch(textSearch) {

    let filtersSearch: any = {
      "$or": [
        { "name": { "$regex": textSearch, "$options": "i" } },
      ]
    };
    return filtersSearch;
  }

  getFilters() {
    let _filters: any = {};
    if(this.textSearchCountries && this.textSearchCountries != '')  _filters.country = this.textSearchCountries
    if(this.textSearchProvinces && this.textSearchProvinces != '')  _filters.province = this.textSearchProvinces

    return _filters;
  }


  search() {
    let endPoint = this.settings.endPoints.groups + this.settings.endPointsMethods.groups.search;

    let body = {
      search:this.textSearch,
      searchCountries: this.textSearchCountries,
      searchProvinces: this.textSearchProvinces
    }

    this.pageService.httpCreate(body, endPoint)
    .then((res) => {
      console.log(res.data)
      this.items = res.data;
    })
    .catch((err) => {
      console.log(err);
      this.pageService.showError(err);
    })
  }

  handleEnable(group) {
    group.enabled = !group.enabled;
    const enabledAction = (group.enabled ? 'habilitado' : 'deshabilitado');

    group.user = group.user.id;
    group.category = group.category.id;


    this.pageService.httpUpdate(group)
      .then(res => {
        this.pageService.showSuccess('El grupo ' + group.name + '  ha sido ' + enabledAction + ' correctamente!');
        this.getItems();

      })
      .catch(err => {
        this.pageService.showError(err);
        this.getItems();
      });
  }

  gotoActivities(id){
    this.pageService.navigateRoute('activities/'+ id )
  }

  gotoWatchGroup(id){

    this.pageService.navigateRoute('/groups/watch/' + id)
  }


}
