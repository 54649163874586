import { Component } from '@angular/core';
import { ItemComponent } from '../../core/item.component';
import { Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['../../core/item.component.scss']
})

export class ActivityComponent extends ItemComponent {
  
  address: any;
  addressGoogle: any;
  latitude: any;
  longitude: any;

  getEndPointLoad() {
    return this.pageService.global.settings.endPoints.trainings;
  }

  getEndPointUpdate() {
    return this.pageService.global.settings.endPoints.trainings;
  }


  setNewAddress(newAddress) {
    this.address = newAddress;
  }

  setNewLatitude(newLatitude) {
    this.latitude = newLatitude;
  }

  setNewLongitude(newLongitude) {
    this.longitude = newLongitude;
  }

  setNewAddressGoogle(newAddressGoogle) {
    this.addressGoogle = newAddressGoogle;
  }



  getFormNew(){
    return this.formBuilder.group({
      id: [null, Validators.required],
      name: [null, Validators.required],
      date: [null, Validators.required],
      schedule: [null, Validators.required],
      description: [null, Validators.required],
      group: [null, Validators.required]

    })
  }


  getFormEdit(item){
    console.log('ITEM: ', item)
    item.date = moment(item.date).format("YYYY-MM-DD");
    item.schedule = moment(item.schedule).format("HH:mm");

    return this.formBuilder.group({
      id: [item.id],
      name: [item.name, Validators.required],
      date: [item.date, Validators.required],
      schedule: [item.schedule, Validators.required],
      description: [item.description, Validators.required],
      group: [item.group, Validators.required]

    })
  }

  itemLoaded(item) {
    this.address = item.address;
    this.latitude = item.location.coordinates[1];
    this.longitude = item.location.coordinates[0];
    this.addressGoogle = item.addressGoogle;
  }

  savePre(item) {
    item.date = moment(item.date);
    item.schedule = moment(`${item.date}${item.schedule}`).add(3, 'hours').format("MM/DD/YYYY hh:mm");

    item.location = {
      type:'Point',
      coordinates:[this.longitude, this.latitude]
    }

    item.address = this.address ;
    item.addressGoogle  =  this.addressGoogle;
 
  }

  savePostNavigate(item) {
    this.pageService.navigateRoute('/activities/' + item.group);
  }

}
