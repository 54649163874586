<div class="container">
  <div class="row section-header">
    <div class="col-sm-6">
      <h2 class="section-title text-primary">Imagenes de la home</h2>
    </div>
    <div class="col-sm-6">
      <button type="button" class="btn btn-primary ml-2" (click)="openModal(categoryModal)">
        <i class="fa fa-plus"></i> Crear
      </button>
    </div>
  </div>

  <div class="row p-3">
    <div class="table-responsive">
      <table class="table">
        
        <thead class="thead-light">
          <tr>
            <th class="text-left" >
              <button type="button" class="btn btn-light" (click)="sort('name')" disabled>
                Nombre
              </button>
            </th>
            
            <th></th>
          </tr>
        </thead>

        <tbody>
          <!-- Cargador -->
          <tr *ngIf="loading" class="text-center">
            <td colspan="6">
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Cargando...
            </td>
          </tr>
          <!-- Texto cuando no hay resultados  -->
          <tr *ngIf="!loading && items.length == 0" class="text-center">
            <td colspan="6">
              <span class="text-muted">
                <i class="fa fa-close" aria-hidden="true"></i>
                No hay resultados para mostrar
              </span>
            </td>
          </tr>
          
          <tr *ngFor="let item of items">
            <td class="px-4 text-left clickable" >
              {{ item.event?.name }}
              <mat-icon class="lock-icon" *ngIf="!item.enabled">lock</mat-icon>
            </td>

            <td class="text-right">
              <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <!-- <button mat-menu-item (click)="openModal(categoryModal)">
                  <mat-icon>create</mat-icon>Editar
                </button> -->
                <button mat-menu-item (click)="handleEnable(item)">
                  <mat-icon>{{item.enabled? 'lock' : 'lock_open'}}</mat-icon>{{item.enabled? 'Deshabilitar' : 'Habilitar'}}
                </button>
                <button mat-menu-item (click)="deleteEventHome(item)">
                  <mat-icon>delete_forever</mat-icon>Borrar
                </button>
              </mat-menu>
            </td>
          </tr>
          
        </tbody>

      </table>
    </div>
  </div>
  
  <div class="row">
    <div class="col-4 offset-4 d-flex justify-content-center">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{'disabled': page <= 1}" ><a class="page-link" (click)="previousPage()">Anterior</a></li>
          <li class="page-item "><a class="page-link">{{page}}</a></li>
          <li class="page-item" [ngClass]="{'disabled': pages <= page}"><a class="page-link" (click)="nextPage()">Siguiente</a></li>
        </ul>
      </nav>
    </div>
    <div class="col-md-4 text-md-right text-center p-2">
      <span class="text-muted">
        Total de páginas {{pages}}
      </span>
    </div>
  </div>

  <ng-template #categoryModal let-modal>
    
    <div class="modal-header">
      <h4 class="modal-title m-0" id="modal-basic-title" *ngIf="!disabled">{{ creating ? 'Crear slider' : 'Editar slider' }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body mt-2" style="height: auto;">
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Ingrese nombre del evento. . ." aria-label="Recipient's username"
            aria-describedby="button-addon2"  [(ngModel)]="eventSerarch">
          <div class="input-group-append">
            <button class="btn btn-outline-primary" (click)="getEvents()" type="button" id="button-addon2"><i class="fa fa-search"
                aria-hidden="true"></i></button>
          </div>
        </div>
        <div   class="col-12" *ngIf="events?.length == 0">
          <p class="text-center text-secondary" >No se encontraron eventos</p>
        </div>

         <div  class=" card w-100 mt-2"  *ngIf="events?.length > 0">
            <ul  style="list-style: none;" class="pl-2"  >

                <li *ngFor="let event of events"  class="mt-1 ">
                    <label for="{{event.name}}" >
                        <input id="{{event.name}}" [value]='event.id' type="radio" class="mr-1" name="event" (click)="slectedEvent($event.target.value)">
                        {{event.name}}
                    </label>
                </li>
            </ul>
        </div> 
    </div>

    <div class="modal-footer" >
      <button type="button" class="btn btn-success" [disabled]="!eventSelected"  (click)="saveItemModal()"><mat-icon>save</mat-icon> Guardar</button>
    </div>
 
  </ng-template>
</div>