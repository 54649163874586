import { Component } from '@angular/core';
import { ItemComponent } from '../../core/item.component';
import { Validators } from '@angular/forms';
import { mailFormat } from '../../core/forms/validators/mailFormat';
import { Params } from '@angular/router';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['../../core/item.component.scss']
})

export class GroupComponent extends ItemComponent {
  
  address: any;
  addressGoogle: any;
  latitude: any;
  longitude: any;


  getEndPointLoad() {
    return this.pageService.global.settings.endPoints.groups;
  }

  setNewAddress(newAddress) {
    this.address = newAddress;
  }

  setNewLatitude(newLatitude) {
    this.latitude = newLatitude;
  }

  setNewLongitude(newLongitude) {
    this.longitude = newLongitude;
  }

  setNewAddressGoogle(newAddressGoogle) {
    this.addressGoogle = newAddressGoogle;
  }

  getPopulates() {
    return ['category'];
  }

  itemLoaded(item) {
    this.address = item.address;
    this.latitude = item.location.coordinates[1];
    this.longitude = item.location.coordinates[0];
    this.addressGoogle = item.addressGoogle;
  }

  getFormNew(){
    return this.formBuilder.group({
      id: [null],
      name: [null, Validators.required],
      description: [null, Validators.required],
      category: [null, Validators.required],
      emailAddress: [null, Validators.compose([Validators.required, Validators.email, mailFormat()])],
      gender: [null, Validators.required],
      picture: [null],

    })
    
  }

  getFormEdit(item){
    return this.formBuilder.group({
      id: [item.id],
      name: [item.name, Validators.required],
      description: [item.description, Validators.required],
      category: [item.category.name, Validators.required],
      emailAddress: [item.emailAddress, Validators.compose([Validators.required, Validators.email, mailFormat()])],
      gender: [item.gender, Validators.required],
      picture: [item.picture],
    })
  }

}
