
<div class="container">
  <nav aria-label="breadcrumb" class="mt-2">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/administrators']" routerLinkActive="router-link-active">
          Administradores</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Gestionar Administrador</li>
    </ol>
  </nav>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    
    <div class="form-row">
        <div class="col-md-6 ">

            <div class="form-group ">
              <label>E-Mail</label>
              <input type="email" class="form-control " formControlName="emailAddress" [readonly]="noLogged">
              <small *ngIf="!isFieldValid('emailAddress')" class="form-text text-danger">
                {{getFieldError('emailAddress')}}
              </small>
            </div>
        </div>
    </div>

     
    <div class="form-row">
          <div class="col-md-2 col-sm-12">
              <button  class="btn btn-secondary btn-sm mb-2 w-100" (click)="changePassword()" [disabled]="noLogged" *ngIf="!creating">
                <i class="fa fa-lock" aria-hidden="true"></i>
                Cambiar contraseña
              </button>
        </div>

        <div class="col-md-2 col-sm-12">
              <button  class="btn btn-info btn-sm mb-2 w-100" type="button" [disabled]="process" (click)="recoveryPassword()" *ngIf="!creating">
                <i class="fa fa-lock" aria-hidden="true"></i>
                Recuperar contraseña
              </button>
        </div>

        <div class="col-md-2 col-sm-12">
              <button class="btn btn-primary btn-sm w-100 mb-2" type="submit" [disabled]="form.invalid || noLogged && !creating" >
                <i class="fa fa-check" aria-hidden="true"></i> GUARDAR
              </button>
        </div>
    </div>

    <hr>

  </form>
</div>