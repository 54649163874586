export class Settings {
  // Global Settings
  public static APP_NAME = 'Diproach CMS';
  public static APP_VERSION = '0.0.1';


  // EndPoints
  public static endPoints = {
    changePassword: '/changePassword',
    coupons: '/coupons',
    events: '/events',
    files: '/files',
    recoveryPassword: '/recoveryPassword',
    settings: '/settings',
    users: '/users',
    inscriptions: '/inscriptions',
    administrators: '/administrators',
    categories: '/categories',
    eventsFavorites: '/eventsFavorites',
    eventsHome: '/eventsHome',
    raceCategories:'/raceCategories',
    tShirtSizes: '/tShirtSizes',
    groups: '/groups',
    trainings: '/trainings'


  };

  public static endPointsMethods = {
    administrators: {
      login: '/login'
    },
    events: {
      getCountries: '/getCountries',
      getEvents: '/getEvents',
      getProvinces: '/getProvinces'
    },
    eventsHome: {
      getIds: '/getIds'
    },
    groups: {
      getGroups: '/getGroups'
    },
    inscriptions: {
      searchInscriptions: '/searchInscriptions'
    },
    users: {
      recoveryPassword: '/recoveryPassword'
    }
  };

  public static storage = {
    user: 'movent.administrator'
  };

  public static roles = {
    administrators: {
      administrator: 'administrator'
    }
  };

  public static pictures = {
    detail: 'detail',
    gallery: 'gallery'
  }

  public static tShirtSizes = [
    'XS',
    'S',
    'M',
    'L',
    'XL',
    'XXL'
  ];

  public static inscriptions = {
    status: {
      payed: {
        code: 'payed'
      },
      pending: {
        code: 'pending'
      },
    }
  };

  public static routes: any = {
    root: { path: '', redirectTo: '/login', pathMatch: 'full' },
    // administrators: { path: 'administrators', data: { roles: ['administrator'] } },
    activities: { path: 'activities/:id', data: { roles: ['administrator'] } },
    activity:{ path: 'activities/:action/:id' , data: { roles: ['administrator'] }},
    administrators: { path: 'administrators', data: { roles: ['administrator'] } },
    administratorChangePassword: { path: 'administrators/edit/:id/changePassword' , data: { roles: ['administrator'] }},
    administrator: { path: 'administrators/edit/:id', data: { roles: ['administrator'] } },
    codes: { path: 'codes', data: { roles: ['administrator'] } },
    code:{ path: 'codes/:action/:id', data: { roles: ['administrator'] } },
    enrolled: { path: 'enrolled/:id', data: { roles: ['administrator'] } },
    events: { path: 'events', data: { roles: ['administrator'] } },
    event:{ path: 'events/:action/:id', data: { roles: ['administrator'] } },
    groups: { path: 'groups', data: { roles: ['administrator'] } },
    group:{ path: 'groups/:action/:id', data: { roles: ['administrator'] } },
    home: { path: 'home', data: { roles: ['administrator'] } }, // Change this please...
    login: { path: 'login'},
    organizators: { path: 'organizators', data: { roles: ['administrator'] } },
    organizator:{ path: 'organizators/:action/:id', data: { roles: ['administrator'] } },
    race_types: { path: 'race-types', data: { roles: ['administrator'] } },
    sponsors: { path: 'sponsors', data: { roles: ['administrator'] } },
    sponsor: { path: 'sponsors/:action/:id', data: { roles: ['administrator'] } },
    sliders: { path: 'sliders' , data: { roles: ['administrator'] }},
    user: { path: 'users/:action/:id', data: { roles: ['administrator'] }},
    users: { path: 'users' , data: { roles: ['administrator'] }}
  };
}
