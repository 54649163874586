<div class="container">
  <div class="row section-header">
    <div class="col-sm-6">
      <h2 class="section-title text-primary">Tipos de eventos</h2>
    </div>
    <div class="col-sm-6">
      <button type="button" class="btn btn-primary ml-2" (click)="openModal(categoryModal)">
        <i class="fa fa-plus"></i> Crear
      </button>
    </div>
  </div>


  <div class="row mt-4 mb-4">

    <div class="input-group col-sm-6 mb-3">
      <input type="text" class="form-control" placeholder="Buscar" aria-label="Recipient's username"
        aria-describedby="button-addon2" [(ngModel)]="textSearch">
      <div class="input-group-append">
        <button class="btn btn-outline-primary" type="button" id="button-addon2"><i class="fa fa-search"
            aria-hidden="true" (click)="getItems()"></i></button>
      </div>
    </div>

  </div>

  <div class="row p-3">
    <div class="table-responsive">
      <table class="table">

        <thead class="thead-light">
          <tr>
            <th class="text-left">
              <button type="button" class="btn btn-light" (click)="sort('name')">
                Nombre
                <i class="fa" [ngClass]="{'fa-arrow-down': sortField.name == 1,'fa-arrow-up': sortField.name == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>

            <th></th>
          </tr>
        </thead>

        <tbody>
          <!-- Cargador -->
          <tr *ngIf="loading" class="text-center">
            <td colspan="6">
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Cargando...
            </td>
          </tr>
          <!-- Texto cuando no hay resultados  -->
          <tr *ngIf="!loading && items.length == 0" class="text-center">
            <td colspan="6">
              <span class="text-muted">
                <i class="fa fa-close" aria-hidden="true"></i>
                No hay resultados para mostrar
              </span>
            </td>
          </tr>

          <tr *ngFor="let item of items">
            <td class="px-4 text-left clickable" (click)="openModal(categoryModal, item, true)">
              {{ item.name }}
              <mat-icon class="lock-icon" *ngIf="!item.enabled">lock</mat-icon>
            </td>

            <td class="text-right">
              <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openModal(categoryModal, item)">
                  <mat-icon>create</mat-icon>Editar
                </button>
                <button mat-menu-item (click)="handleEnable(item)">
                  <mat-icon>{{item.enabled? 'lock' : 'lock_open'}}</mat-icon>{{item.enabled? 'Deshabilitar' : 'Habilitar'}}
                </button>
              </mat-menu>
            </td>
          </tr>

        </tbody>

      </table>
    </div>
  </div>

  <div class="row">
    <div class="col-4 offset-4 d-flex justify-content-center">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{'disabled': page <= 1}"><a class="page-link"
              (click)="previousPage()">Anterior</a></li>
          <li class="page-item "><a class="page-link">{{page}}</a></li>
          <li class="page-item" [ngClass]="{'disabled': pages <= page}"><a class="page-link"
              (click)="nextPage()">Siguiente</a></li>
        </ul>
      </nav>
    </div>
    <div class="col-md-4 text-md-right text-center p-2">
      <span class="text-muted">
        Total de páginas {{pages}}
      </span>
    </div>
  </div>

  <ng-template #categoryModal let-modal>

    <div class="modal-header">
      <h4 class="modal-title m-0" id="modal-basic-title" *ngIf="!disabled">{{ creating ? 'Agregar un tipo carrera' : 'Editar
        categoría' }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body mt-2" style="height: auto;">
      <div class="form-group">
        <label>Nombre: </label>
        <input type="text" class="form-control" [(ngModel)]="name" [disabled]="disabled" placeholder="Running">
      </div>


      <div class="form-group border border-1 p-2">
        <div class="row">
          <div class="col-3">
            <img class="mx-auto img-thumbnail" onError="this.src='../../../assets/imgs/Default_Image_Thumbnail.png'"
              [src]="filesUrl + picture" />
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-outline-primary float-right" (click)="changeImage()" *ngIf="!disabled">
              <i class="fa fa-upload" aria-hidden="true"></i>  Subir imagen
            </button>
          </div>
        </div>
      </div>

    </div>


    <div class="modal-footer" *ngIf="!disabled">
      <button type="button" class="btn btn-outline-dark" (click)="closeModal()"><i
          class="fa fa-times" aria-hidden="true"></i> Cancelar</button>
      <button type="button" class="btn btn-success" [disabled]="modalSaveDisabled()" (click)="saveItemModal()"><i
          class="fa fa-floppy-o" aria-hidden="true"></i> Guardar</button>
    </div>

  </ng-template>
</div>