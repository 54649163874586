<div class="container">

  <div class="section-header">
    <h2 class="section-title text-primary">Grupos de entrenamientos</h2>
  </div>

  <div class="row mt-4 mb-4">

    <div class="form-group col-sm-5 mb-3">
      <input type="text" class="form-control" placeholder="Buscar" aria-label="Recipient's username"
        aria-describedby="button-addon2" [(ngModel)]="textSearch">
    </div>

    <div class="form-group col-sm-5 mb-3">
      <div class="row">

        <div class="form-group col-sm-6">
          <input type="text" class="form-control" placeholder="Buscar por pais" aria-label="Recipient's username"
          aria-describedby="button-addon2" [(ngModel)]="textSearchCountries">
        </div>

        <div class="form-group col-sm-6">
          <input type="text" class="form-control" placeholder="Buscar por provincia" aria-label="Recipient's username"
          aria-describedby="button-addon2" [(ngModel)]="textSearchProvinces">
        </div>
      </div>
    </div>

    <div class="form-group col-sm-2 mb-3">
      <button class="btn btn-outline-primary" type="button" (click)="getItems()">
        <i class="fa fa-search" aria-hidden="true"></i> Buscar
      </button>
    </div>
  </div>

  <div class="row p-3">
    <div class="table-responsive">
      <table class="table">

        <thead class="thead-light">
          <tr>
            <th class="text-left align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center" (click)="sort('name')">
                Nombre
                <i class="fa pl-2" [ngClass]="{'fa-arrow-down': sortField.name == 1,'fa-arrow-up': sortField.name == -1}"
                aria-hidden="true"></i>
              </button>
            </th>
            <th class="text-center align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center m-auto" (click)="sort('coach')" disabled>
                Entrenador
                <i class="fa pl-2" [ngClass]="{'fa-arrow-down': sortField.coach == 1,'fa-arrow-up': sortField.coach == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>
            <th class="text-center align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center m-auto" (click)="sort('mainActivity')" disabled>
                Actividad actual
                <i class="fa pl-2"
                  [ngClass]="{'fa-arrow-down': sortField.mainActivity == 1,'fa-arrow-up': sortField.mainActivity == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <!-- Cargador -->
          <tr *ngIf="loading" class="text-center">
            <td colspan="6">
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Cargando...
            </td>
          </tr>
          <!-- Texto cuando no hay resultados  -->
          <tr *ngIf="!loading && items.length == 0" class="text-center">
            <td colspan="6">
              <span class="text-muted">
                <i class="fa fa-close" aria-hidden="true"></i>
                No hay resultados para mostrar
              </span>
            </td>
          </tr>

          <tr *ngFor="let item of items">
            <td class="px-4 text-left clickable" (click)="gotoWatchGroup(item.id)">
              {{ item.name }}
              <mat-icon class="lock-icon" *ngIf="!item.enabled">lock</mat-icon>
            </td>
            <td class="px-4 text-center">
              {{ item.user.firstName }} {{ item.user.lastName }}
            </td>
            <td class="px-4 text-center">
              {{ item.category.name }}
            </td>


            <td class="text-right">
              <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="gotoActivities(item.id)">
                  <mat-icon>list_alt</mat-icon>Lista
                </button>
                <button mat-menu-item (click)="handleEnable(item)">
                  <mat-icon>{{item.enabled? 'lock' : 'lock_open'}}</mat-icon>{{item.enabled? 'Deshabilitar' : 'Habilitar'}}
                </button>
                <button mat-menu-item (click)="remove(item)">
                  <mat-icon>delete_forever</mat-icon>Borrar
                </button>
              </mat-menu>
            </td>
          </tr>

        </tbody>
      </table>

    </div>
  </div>

  <div class="row">
    <div class="col-4 offset-4 d-flex justify-content-center">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{'disabled': page <= 1}"><a class="page-link"
              (click)="previousPage()">Anterior</a></li>
          <li class="page-item "><a class="page-link">{{page}}</a></li>
          <li class="page-item" [ngClass]="{'disabled': pages <= page}"><a class="page-link"
              (click)="nextPage()">Siguiente</a></li>
        </ul>
      </nav>
    </div>
    <div class="col-md-4 text-md-right text-center p-2">
      <span class="text-muted">
        Total de páginas {{pages}}
      </span>
    </div>
  </div>
</div>