import { Component } from '@angular/core';
import { ItemComponent } from '../../core/item.component';
import { Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['../../core/item.component.scss']
})

export class EventComponent extends ItemComponent {
  

  name: string;
  picture: string;
  creating = false;
  disabled = false;

  address: any;
  addressGoogle: any;
  date: any;
  latitude: any;
  longitude: any;
  categories: any;
  country: any;
  province: any;
  pictures: any = [];
  picturesGallery: any = [];
  raceCategories: any = [];
  schedule: any;

  initializePre(){
    this.getCategories();
  }

  savePre(item){
    const location = {
      type:'Point',
      coordinates: [this.longitude, this.latitude]
    };
    item.address = this.address;
    item.addressGoogle = this.addressGoogle;
    item.location = location;
    item.province = this.province;
    item.country = this.country;
    item.date = moment(this.date);
    item.pictures = this.pictures;
    item.picturesGallery = this.picturesGallery;
    if(!item.user) item.administrator = this.global.user.id;

    let ext = this.schedule.split(':');
    item.schedule = moment().hour(ext[0]).minute(ext[1]);
  }

  itemLoaded(item){
    if(item){
      this.date = moment(item.date).format('YYYY-MM-DD');
      this.schedule = moment(item.schedule).format('HH:mm');
      this.pictures = item.pictures || [];
      this.picturesGallery = item.picturesGallery || [];
      this.getRaceCategories();

      this.address = item.address;
      this.addressGoogle = item.addressGoogle;
      this.longitude = item.location.coordinates[0];
      this.latitude = item.location.coordinates[1];
    }
  }

  getCategories(){
    const endPoint = this.settings.endPoints.categories;
    const filter = { enabled: true };
    this.pageService.httpGetAll( filter, {name: 1}, [], 1, [], endPoint )
      .then( res => {
        this.categories = res.data;
      })
      .catch( err => {
        this.pageService.showError(err);
      })
  }

  getRaceCategories(){
    const endPoint = this.settings.endPoints.raceCategories;
    const filter = { event: this.item.id };
    this.pageService.httpGetAll( filter, {name: 1}, [], 1, [], endPoint )
      .then( res => {
        this.raceCategories = res.data;
      })
      .catch( err => {
        this.pageService.showError(err);
      })
  }

  setNewAddress(newAddress) {
    this.address = newAddress;
  }

  setNewLatitude(newLatitude) {
    this.latitude = newLatitude;
  }

  setNewLongitude(newLongitude) {
    this.longitude = newLongitude;
  }

  setNewAddressGoogle(newAddressGoogle) {
    this.addressGoogle = newAddressGoogle;
  }

  setNewProvince(NewProvince) {
    this.province = NewProvince;
  }

  setNewCountry(NewCountry) {
    this.country = NewCountry;
  }

  getFormNew(){
    return this.formBuilder.group({
      address: [null],
      addressGoogle: [null],
      administrator: [null],
      category: [null, Validators.required],
      country: [null],
      date: [null, Validators.required],
      description: [null, Validators.required],
      enabled: [true],
      location: [null],
      name: [null, Validators.required],
      pictures: [null],
      province: [null],
      schedule: [null, Validators.required],
      showInscriptionsQuantity: [false],
      type: ['administrator', Validators.required],
      user: [null]
    })
  }

  getFormEdit(item){
    return this.formBuilder.group({
      id: [item.id],
      address: [item.address],
      addressGoogle: [item.addressGoogle],
      administrator: [item.administrator],
      category: [item.category, Validators.required],
      classifications: [item.classifications],
      chronogram: [item.chronogram],
      country: [item.country],
      date: [item.date, Validators.required],
      description: [item.description, Validators.required],
      enabled: [item.enabled],
      location: [item.location],
      medicalCertificate: [item.medicalCertificate],
      name: [item.name, Validators.required],
      others: [item.others],
      pictures: [item],
      picturesGallery: [item.picturesGallery],
      province: [item.province],
      rangeType: [item.rangeType],
      responsibilityDeployment: [item.responsibilityDeployment],
      rules: [item.rules],
      schedule: [item.schedule],
      type: [item.type],
      user: [item.user]
    })
  }

  addImage(type) {
    this.pageService.showImageUpload()
      .then((resp: any) => {
        if (type == this.settings.pictures.gallery) this.picturesGallery.push(resp.data.file);
        if (type == this.settings.pictures.detail) this.pictures.push(resp.data.file);
      })
      .catch((error: any) => { });
  }

  removePicture(picture, type) {
    if (type == this.settings.pictures.gallery) this.picturesGallery = this.picturesGallery.filter(pic => pic !== picture);
    if (type == this.settings.pictures.detail) this.pictures = this.pictures.filter(pic => pic !== picture);
  }

  downloadClassifications(){
    if(this.item.classifications){
      const url = this.filesUrl + this.item.classifications;
      window.open(url, '_system', 'location=yes');
    }
    else  this.pageService.showError("No hay resultados disponibles para este evento");
  }

  downloadMedicalCertificate(){
    if(this.item.medicalCertificate){
      const url = this.filesUrl + this.item.medicalCertificate;
      window.open(url, '_system', 'location=yes');
    }
    else  this.pageService.showError("No hay certificado médico disponible para este evento");
  }

  downloadResponsibilityDeployment(){
    if(this.item.responsibilityDeployment){
      const url = this.filesUrl + this.item.responsibilityDeployment;
      window.open(url, '_system', 'location=yes');
    }
    else  this.pageService.showError("No hay certificado médico disponible para este evento");
  }

  openModal(content, item?, disabled?, buttonType?) {
    this.disabled = disabled ? true : false;
    
    if (item) {
      this.itemSelected = item;
      this.creating = false;
      this.name = this.itemSelected.name;
      this.picture = this.itemSelected.picture;
    } else {
      this.itemSelected = {};
      this.creating = true;
    }

    // this.buttonTypePressed = buttonType;

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.clearVariables();
    }, (reason) => {
      this.closeResult = `Dismissed`;
      this.clearVariables();
    });
  }

  clearVariables() {
    this.name = undefined;
    this.picture = undefined;
    // this.buttonSearchPressed = false;
  }
  
  closeModal() {
    this.modalService.dismissAll();
  }

  saveItemModal() {
    this.itemSelected.name = this.name;
    this.itemSelected.picture = this.picture;

    if (this.creating) {
      this.pageService.httpCreate(this.itemSelected).then(res => {
        this.pageService.showSuccess('Creado con éxito');
        this.closeModal();
        // this.getItems();
      }).catch(e => this.pageService.showError(e));
    } else {
      // this.update(this.itemSelected, true);
      this.closeModal();
    }
  }

  onChangeSchedule(){
    this.form.patchValue({
      schedule: this.schedule
    });
  }

  onChangeDate(){
    this.form.patchValue({
      date: this.date
    });
  }

  modalSaveDisabled() {
    if ( !this.name || this.name == '' || !this.picture || this.picture == '' ) return true;

    return false;
  }
}
