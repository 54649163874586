import { Component } from '@angular/core';
import { ItemsComponent } from '../../core/items.component';

@Component({
  selector: 'app-race-types',
  templateUrl: './race-types.component.html',
  styleUrls: ['../../core/items.component.scss']
})
export class RaceTypesComponent extends ItemsComponent {

  // items: any = [
  // ]
  name: string;
  picture: string;
  creating = false;
  disabled = false;

  getEndPoint(){
    return this.settings.endPoints.categories;
  }

  getHttp(){
    return this.settings.endPoints.categories;
  }

  handleEnable(category) {

    category.enabled = !category.enabled;
    const enableAction = category.enabled? 'habilitada' : 'deshabilitada';

    this.pageService.httpUpdate(category, this.getEndPoint())
      .then(res => {
        this.pageService.showSuccess('La categoria "' + category.name + '" ha sido ' + enableAction + ' correctamente!');
      })
      .catch(err => this.pageService.showError(err));
  }

  openModal(content, item?, disabled?) {
    this.clearVariables();
    this.disabled = disabled ? true : false;
    
    if (item) {
      this.itemSelected = item;
      this.creating = false;
      this.name = this.itemSelected.name;
      this.picture = this.itemSelected.picture;
    } else {
      this.itemSelected = {};
      this.creating = true;
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.clearVariables();
    }, (reason) => {
      this.closeResult = `Dismissed`;
      this.clearVariables();
    });
  }
  
  closeModal() {
    this.modalService.dismissAll();
  }

  clearVariables() {
    this.name = undefined;
    this.picture = undefined;
  }
  
  saveItemModal() {
    this.itemSelected.name = this.name;
    this.itemSelected.picture = this.picture;

    if (this.creating) {
      this.pageService.httpCreate(this.itemSelected, this.getEndPoint()).then(res => {
        this.pageService.showSuccess('Creado con éxito');
        this.closeModal();
        this.getItems();
      }).catch(e => this.pageService.showError(e));
    } else {
      this.update(this.itemSelected, true);
      this.closeModal();
    }
  }

  modalSaveDisabled() {
    if ( !this.name || this.name == '' || !this.picture || this.picture == '' ) return true;
    return false;
  }

  changeImage() {
    this.pageService.showImageUpload()
      .then((resp: any) => {
        this.picture = resp.data.file;
      })
      .catch((err) => { 
        this.pageService.showError(err);
      });
  }

  getFilters() {
    let _filters = {};

    return _filters;
  }

  getFiltersSearch(textSearch) {

    let filtersSearch: any = {
      "$or": [
        { "name": { "$regex": textSearch, "$options": "i" } },
      ]
    };

    return filtersSearch;
  }
}
