import { Component } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import * as moment from 'moment';
import { ItemsComponent } from '../../core/items.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['../../core/items.component.scss']
})
export class EventsComponent extends ItemsComponent {

  name: string;
  picture: string;
  creating = false;
  disabled = false;

  categories: any = [];
  countries: any = [];
  dateFrom: any;
  dateTo: any;
  eventsHome: any = [];
  provinces: any = [];
  selectedCategory: any;
  selectedCountry: any;
  selectedProvince: any;
  textSearchCountry: string;
  textSearchProvince: string;

  initialize(){
    this.getEventsHome();
    this.getCategories();
  }

  getEndPointLoad(){
    return this.settings.endPoints.events + this.settings.endPointsMethods.events.getEvents;
  }

  getPopulates(){
    return ['user','category', 'administrator'];
  }

  goToEnrrolled( evnetId ) {
    this.pageService.navigateRoute('/enrolled/' + evnetId);
  }

  handleEnable(event) {
    event.enabled = !event.enabled;
    const enabledAction = (event.enabled ? 'habilitado' : 'deshabilitado');

    let data = {
      id: event.id,
      enabled: event.enabled
    };
    this.pageService.httpUpdate(data)
      .then(res => {
        this.pageService.showSuccess('El usuario ' + event.name + ' ha sido ' + enabledAction + ' correctamente!')
      })
      .catch(err => this.pageService.showError(err));
  }

  getEventsHome(){
    const endPoint = this.settings.endPoints.eventsHome + this.settings.endPointsMethods.eventsHome.getIds;
    this.pageService.httpGetAll( {}, {}, [], 1, [], endPoint )
    .then( res => {
      this.eventsHome = res.data;
    })
    .catch( err => {
      this.pageService.showError(err);
    })
  }

  getCategories(){
    const endPoint = this.settings.endPoints.categories;
    const filter = { enabled: true };
    this.pageService.httpGetAll( filter, {name: 1}, [], 1, [], endPoint )
      .then( res => {
        this.categories = res.data;
      })
      .catch( err => {
        this.pageService.showError(err);
      })
  }

 setFeatured(event){
    const endPoint = this.settings.endPoints.events + '/' + event.id;
    const item = {
      id: event.id,
      featured: !event.featured
    }
    const actionWord = item.featured ? '' : ' ya no';

      this.pageService.httpUpdate(item)
        .then( res => {
          this.pageService.showSuccess('El evento ' + event.name + actionWord + ' es un evento destacado');
          this.getItems();
        })
        .catch( err => {
          this.pageService.showError(err);
        })
  }
  
  setEventHome(event){
    
    if(this.eventsHome.eventsIds.includes(event.id)){

      const eventHome = this.eventsHome.eventsHome.find(evHome =>
        evHome.event == event.id);
      
      this.eventsHome.eventsIds = this.eventsHome.eventsIds.filter(evId => evId !== event.id);
      this.eventsHome.eventsIds = this.eventsHome.eventsHome.filter(evId => evId !== event.id);

      const endPoint = this.settings.endPoints.eventsHome + '/' + eventHome.id;
      this.pageService.httpDelete(endPoint)
        .then( res => {
          this.pageService.showSuccess('El evento ' + event.name + ' ya no es un evento principal');
        })
        .catch( err => {
          this.pageService.showError(err);
        })
    }
    else{
      
      const endPoint = this.settings.endPoints.eventsHome;
      const item = { event: event.id };
      this.pageService.httpCreate(item, endPoint)
        .then( res => {
          this.eventsHome.eventsIds.push(event.id);
          this.eventsHome.eventsHome.push(res.data);
          this.pageService.showSuccess('El evento ' + event.name + ' ha sido agregado como evento principal');
        })
        .catch( err => {
          console.log(err);
        })
    }
  }

  openModal(content, item?, disabled?) {
    this.disabled = disabled ? true : false;
    
    if (item) {
      this.itemSelected = item;
      this.creating = false;
      this.name = this.itemSelected.name;
      this.picture = this.itemSelected.picture;
    } else {
      this.itemSelected = {};
      this.creating = true;
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.clearVariables();
    }, (reason) => {
      this.closeResult = `Dismissed`;
      this.clearVariables();
    });
  }
  
  closeModal() {
    this.modalService.dismissAll();
  }

  clearVariables() {
    this.name = undefined;
    this.picture = undefined;
  }
  
  saveItemModal() {
    this.itemSelected.name = this.name;
    this.itemSelected.picture = this.picture;

    if (this.creating) {
      this.pageService.httpCreate(this.itemSelected).then(res => {
        this.pageService.showSuccess('Creado con éxito');
        this.closeModal();
        this.getItems();
      }).catch(e => this.pageService.showError(e));
    } else {
      this.update(this.itemSelected, true);
      this.closeModal();
    }
  }

  modalSaveDisabled() {
    if ( !this.name || this.name == '' || !this.picture || this.picture == '' ) return true;

    return false;
  }

  changeImage() {
    this.pageService.showImageUpload()
      .then((resp: any) => {
        this.picture = resp.data.file;
      })
      .catch((err) => { 
        this.pageService.showError(err);
      });
  }

  getFilters() {
    let dateFrom;
    let dateTo;
    if(this.dateFrom) dateFrom = moment(this.dateFrom).startOf('D').toISOString();;
    if(this.dateTo) dateTo = moment(this.dateTo).endOf('D').toISOString();;

    let _filters = {
      category: undefined,
      country: undefined,
      date: undefined,
      province: undefined
    };

    if (dateFrom && !dateTo) _filters.date = { $gte: dateFrom};

    if (dateTo && !dateFrom) _filters.date = { $lte: dateTo };
    
    if (dateFrom && dateTo) _filters.date = {
      $gte: dateFrom,
      $lte: dateTo
    };

    if(this.selectedCategory){
      _filters.category = this.selectedCategory
    }
    if(this.selectedCountry && this.selectedCountry !== '' && this.selectedProvince !== 'País'){
      _filters.country = this.selectedCountry.toLowerCase();
    }
    if(this.selectedProvince && this.selectedProvince !== '' && this.selectedProvince !== 'Provincia'){
      _filters.province = this.selectedProvince.toLowerCase();
    }
    if(this.textSearchCountry && this.textSearchCountry != '')  _filters.country = this.textSearchCountry
    if(this.textSearchProvince && this.textSearchProvince != '')  _filters.province = this.textSearchProvince
    return _filters;
  }

  getFiltersSearch(textSearch) {

    let filtersSearch: any = {
      "$or": [
        { "name": { "$regex": textSearch, "$options": "i" } },
      ]
    };
    return filtersSearch;
  }
  
  gotoInscriptos(id){
    this.pageService.navigateRoute('/enrolled/' + id)
  }

  
}
