import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivitiesComponent } from './modules/activities/activities.component';
import { ActivityComponent } from './modules/activities/activity.component';
import { AdministratorComponent } from './modules/administrators/administrator.component';
import { AdministratorChangePasswordComponent } from './modules/administrators/administrator.change.password.component';
import { AdministratorsComponent } from './modules/administrators/administrators.component';
import { CodesComponent } from './modules/codes/codes.component';
import { CodeComponent } from './modules/codes/code.component';
import { EnrolledComponent } from './modules/enrolled/enrolled.component';
import { EventsComponent } from './modules/events/events.component';
import { EventComponent } from './modules/events/event.component';
import { GroupsComponent } from './modules/groups/groups.component';
import { GroupComponent } from './modules/groups/group.component';

import { HomeComponent } from './modules/home/home.component';
import { HttpGuard } from './core/http.guard';
import { LoginComponent } from './modules/login/login.component';
import { RaceTypesComponent } from './modules/race-types/race-types.component';
import { SponsorsComponent } from './modules/sponsors/sponsors.component';
import { SponsorComponent } from './modules/sponsors/sponsor.component';
import { OrganizatorsComponent } from './modules/organizators/organizators.component';
import { OrganizatorComponent } from './modules/organizators/organizator.component';
import { Settings } from './app.settings';
import { SlidersComponent } from './modules/sliders/sliders.component';
import { UsersComponent } from './modules/users/users.component';

Settings.routes.activities.component = ActivitiesComponent;
Settings.routes.activity.component = ActivityComponent;
Settings.routes.administrators.component = AdministratorsComponent;
Settings.routes.administrator.component = AdministratorComponent;
Settings.routes.administratorChangePassword.component = AdministratorChangePasswordComponent;
Settings.routes.codes.component = CodesComponent;
Settings.routes.code.component = CodeComponent;
Settings.routes.enrolled.component = EnrolledComponent;
Settings.routes.events.component = EventsComponent;
Settings.routes.event.component = EventComponent;
Settings.routes.groups.component = GroupsComponent; 
Settings.routes.group.component = GroupComponent;
Settings.routes.home.component = HomeComponent;
Settings.routes.login.component = LoginComponent;
Settings.routes.organizators.component = OrganizatorsComponent;
Settings.routes.organizator.component = OrganizatorComponent;
Settings.routes.race_types.component = RaceTypesComponent;
Settings.routes.sponsors.component = SponsorsComponent;
Settings.routes.sponsor.component = SponsorComponent;
Settings.routes.sliders.component = SlidersComponent;
Settings.routes.user.component = OrganizatorComponent;
Settings.routes.users.component = UsersComponent;


let routes: Routes = [];
for (let routeKey in Settings.routes) {
  let route = Settings.routes[routeKey];
  let r: any = {};
  if (route.path || route.path == '') r.path = route.path;
  if (route.redirectTo) r.redirectTo = route.redirectTo;
  if (route.pathMatch) r.pathMatch = route.pathMatch;
  if (route.component) r.component = route.component;
  if (route.data) {
    r.data = route.data;
    r.canActivate = [HttpGuard];
  }
  routes.push(r);
}

// routes.push({
//   path: 'pay',
//   loadChildren: () => import('./modules/pay/pay.module').then(m => m.PayPageModule)
// });

console.log(routes);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
