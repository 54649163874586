<div class="container">

  <div class="section-header">
    <h2 class="section-title text-primary">Códigos de descuento</h2>
  </div>

  <div class="row p-3 mt-4">
    <div class="table-responsive">
      <table class="table">
        <thead class="thead-light">
          <tr>
            <th class="text-left align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center" (click)="sort('event')" disabled>
                Evento
                <i class="fa pl-2"
                  [ngClass]="{'fa-arrow-down': sortField.event == 1,'fa-arrow-up': sortField.event == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>
            <th class="text-center align-middle">
              <button type="button" style="display: flex;" class="btn btn-light d-flex align-items-center m-auto" (click)="sort('discount')">
                <span class="pr-1">%</span>Descuento <i class="fa pl-2"
                  [ngClass]="{'fa-arrow-down': sortField.discount == 1,'fa-arrow-up': sortField.discount == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>

            <th class="text-center align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center m-auto" (click)="sort('quantity')">
                Cantidad <i class="fa pl-2"
                  [ngClass]="{'fa-arrow-down': sortField.quantity == 1,'fa-arrow-up': sortField.quantity == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>

            <th class="text-center align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center m-auto" (click)="sort('code')">
                Código <i class="fa pl-2"
                  [ngClass]="{'fa-arrow-down': sortField.code == 1,'fa-arrow-up': sortField.code == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          <!-- Cargador -->
          <tr *ngIf="loading" class="text-center">
            <td colspan="6">
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Cargando...
            </td>
          </tr>
          <!-- Texto cuando no hay resultados  -->
          <tr *ngIf="!loading && items.length == 0" class="text-center">
            <td colspan="6">
              <span class="text-muted">
                <i class="fa fa-close" aria-hidden="true"></i>
                No hay resultados para mostrar
              </span>
            </td>
          </tr>
          
          <tr *ngFor="let item of items">
            <td class="px-4 text-left clickable" (click)="watch(item)">
              {{ item.event?.name }}
              <mat-icon class="lock-icon" *ngIf="!item.enabled">lock</mat-icon>
            </td>
            <td class="px-4 text-center">
              {{ item.discount }}%
            </td>
            <td class="px-4 text-center">
              {{ item.quantity }}
            </td>
            <td class="px-4 text-center">
              {{ item.code }}
            </td>

            <td class="text-right">
              <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="edit(item)">
                  <mat-icon>create</mat-icon>Editar
                </button>
                <button mat-menu-item (click)="handleItemEnablement(item)">
                  <mat-icon>{{ item.enabled ? 'lock' : 'lock_open' }}</mat-icon>{{ item.enabled ? 'Deshabilitar' : 'Habilitar' }}
                </button>
              </mat-menu>
            </td>
        </tr>

        </tbody>
      </table>
    </div>
  </div>

  <div class="row">
    <div class="col-4 offset-4 d-flex justify-content-center">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{'disabled': page <= 1}"><a class="page-link"
              (click)="previousPage()">
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </a></li>
          <li class="page-item "><a class="page-link">{{page}}</a></li>
          <li class="page-item" [ngClass]="{'disabled': pages <= page}"><a class="page-link"
              (click)="nextPage()">
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </a></li>
        </ul>
      </nav>
    </div>
    <div class="col-md-4 text-md-right text-center p-2">
      <span class="text-muted">
        Total de paginas {{pages}}
      </span>
    </div>
  </div>


</div>