import { Component } from '@angular/core';
import { ItemsComponent } from '../../core/items.component';

@Component({
  selector: 'app-sliders',
  templateUrl: './sliders.component.html',
  styleUrls: ['../../core/items.component.scss']
})
export class SlidersComponent extends ItemsComponent {

  eventSerarch: string = "";
  eventSelected: string = "";
  events: any;
  search: boolean = false;

  
  getEndPoint(){
    return this.settings.endPoints.eventsHome;
  }

  getEndPointUpdate(){
    return this.settings.endPoints.eventsHome;
  }

  getPopulates() {
    return ['event'];
  }

  getEvents() {

    let endPoint = this.settings.endPoints.events;
    
    if(this.eventSerarch){
       let filters = {
         "$or": [{"name":{"$regex":this.eventSerarch,"$options":"i"}}],
         enabled: true
        };

    this.pageService.httpSimpleGetAll(endPoint, true, {}, filters)
    .then((res) => {
        this.events = res.data;
    })
    .catch((err) => {
      console.log(err);
      this.pageService.showError(err);
    })
  }

  }

  slectedEvent(event) {
    this.eventSelected = event;
  }

  handleEnable(eventHome) {
    eventHome.enabled = !eventHome.enabled;
    const enabledAction = (eventHome.enabled ? 'habilitado' : 'deshabilitado');

    let data = {
      id: eventHome.id,
      enabled: eventHome.enabled
    };
    this.pageService.httpUpdate(data, this.getEndPointUpdate())
      .then(res => {
        this.pageService.showSuccess('El evento ' + eventHome.event.name + ' ha sido ' + enabledAction + ' correctamente!')
      })
      .catch(err => this.pageService.showError(err));
  }

  deleteEventHome(eventHome){
    if (confirm('¿Está seguro que desea eliminar el evento ' + eventHome.event.name + ' de la home?')){
    const endPoint = this.settings.endPoints.eventsHome + '/' + eventHome.id;
    this.pageService.httpDelete(endPoint)
      .then( res => {
        this.pageService.showSuccess('Se ha eliminado el evento ' + eventHome.event.name + ' de la home');
        this.getItems();
      })
      .catch( err => {
        this.pageService.showError(err);
      })
    }
  }

  saveItemModal() {


    let check = this.items.find((item) => item.event.id == this.eventSelected);

    if(!check) {
      let endPoint = this.settings.endPoints.eventsHome;

      let sliderToCreate = {
        event: this.eventSelected
      }
  
      this.pageService.httpCreate( sliderToCreate, endPoint )
      .then((res) => {
        this.closeModal();
        this.pageService.showSuccess("Slider creado con exito");
        this.getItems();
        this.events = [];
        this.eventSerarch = '';
      })
      .catch((err) => {
        console.log(err);
        this.pageService.showError(err)
      })
    }
    else return this.pageService.showError("El evento ya aparece en la home");

  }

}
