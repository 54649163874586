<div class="container">
  <nav aria-label="breadcrumb" class="mt-2">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/events']" routerLinkActive="router-link-active">Eventos</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">Modificar evento</li>
    </ol>
  </nav>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="needs-validation">
    <div class="form-row">
      <div class="col-md-6 mt-4">
        <fieldset>
          <legend>Datos del evento</legend>
          <div class="form-group">
            <label>Nombre</label>
            <input type="text" class="form-control" [ngClass]="{'is-invalid': !isFieldValid('name')}"
              formControlName="name" [readonly]="isWatching" placeholder="EventoUno">
            <small *ngIf="!isFieldValid('name')" class="form-text text-danger">
              {{getFieldError('name')}}
            </small>
          </div>

          <div class="form-group">
            <label for="validationCustom04" class="form-label">Tipo de evento</label>
            <select class="form-control" id="validationCustom04" formControlName="category" *ngIf="!isWatching">
              <option *ngFor="let category of categories" [value]="category.id">{{category.name}}</option>
            </select>
            <select class="form-control" id="validationCustom04" formControlName="category" *ngIf="isWatching" disabled>
              <option *ngFor="let category of categories" [value]="category.id">{{category.name}}</option>
            </select>
          </div>

          <div class="form-group">
            <label>Fecha</label>
            <input type="date" class="form-control" [readonly]="isWatching" placeholder="3/11/1996" [(ngModel)]="date"
              [ngModelOptions]="{standalone: true}" (change)="onChangeDate()">
          </div>

          <div class="form-group">
            <label for="exampleFormControlInput1">Horario</label>
            <input type="time" class="form-control" id="exampleFormControlInput1" [(ngModel)]="schedule" [ngModelOptions]="{standalone: true}" name="eta"
              [readonly]="isWatching" (change)="onChangeSchedule()">
            <small *ngIf="!isFieldValid('schedule')" class="form-text text-danger">
              {{getFieldError('schedule')}}
            </small>
          </div>
        </fieldset>
      </div>

      <div class="col-md-6 mt-4">
        <fieldset>
          <legend>Dirección</legend>
          <div class="form-group">
            <app-map [latitude]="latitude" [longitude]="longitude" [address]="address" [addressGoogle]="addressGoogle"
              [province]="province" [country]="country"
              (newAddress)="setNewAddress($event)" (newAddressGoogle)="setNewAddressGoogle($event)"
              (newLatitude)="setNewLatitude($event)" (newLongitude)="setNewLongitude($event)"
              (newProvince)="setNewProvince($event)" (newCountry)="setNewCountry($event)" [edit]="true"
              [isWatching]=isWatching>
            </app-map>
          </div>
        </fieldset>
      </div>

    </div>

    <div class="form-row">
      <div class="col-md-12">
        
        <div class="form-group">
          <label for="exampleFormControlTextarea1">Descripción</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="description"></textarea>
        </div>
      </div>

      <div class="col-md-12"  *ngIf="item?.user">
        <div class="form-row ">
          <div class="form-group col-md-4">
            <label for="exampleFormControlTextarea1">Reglamento</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" formControlName="rules"></textarea>
          </div>

          <div class="form-group col-md-4"  *ngIf="item?.user">
            <label for="exampleFormControlTextarea1">Cronograma</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" formControlName="chronogram"></textarea>
          </div>

          <div class="form-group col-md-4"  *ngIf="item?.user">
            <label for="exampleFormControlTextarea1">Otros</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" formControlName="others"></textarea>
          </div>
        </div>
      </div>
    </div>

    <fieldset *ngIf="item?.user" disabled>
      <legend>Categoría</legend>
      <div class="form-row mt-4 mb-4">
        <div class="col-sm-6">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1"
              checked>
            <label class="form-check-label" for="exampleRadios1">
              Por fecha
            </label>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
            <label class="form-check-label" for="exampleRadios2">
              Por año
            </label>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="table-responsive">
          <table class="table">

            <thead class="thead-light">
              <tr>
                <th class="text-center">
                  <button type="button" class="btn btn-light">Nombre</button>
                </th>
                <th class="text-center">
                  <button type="button" class="btn btn-light">Distancia</button>
                </th>
                <th class="text-center">
                  <button type="button" class="btn btn-light">Año</button>
                </th>
                <th class="text-center">
                  <button type="button" class="btn btn-light">Género</button>
                </th>
                <th class="text-center">
                  <button type="button" class="btn btn-light">Precio</button>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let item of raceCategories">
                <td class="px-4 text-center">
                  {{ item.name }}
                </td>
                <td class="px-4 text-center">
                  {{ item.distance }}
                </td>
                <td class="px-4 text-center" *ngIf="item.minimumBirthDate">
                  {{ item.minimumBirthDate | date: 'dd/MM/yy' }} - {{ item.maximumBirthDate | date: 'dd/MM/yy' }}
                </td>
                <td class="px-4 text-center" *ngIf="item.minimumAge">
                  {{ item.minimumAge | date: 'YYYY' }} - {{ item.maximumAge | date: 'YYYY' }}
                </td>
                <td class="px-4 text-center">
                  {{ item.gender }}
                </td>
                <td class="px-4 text-center">
                  {{ item.price }}
                </td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>Fotos</legend>
      <div class="form-row">
        <div class="col-sm-6" *ngIf="item?.user">
          <div class="form-group">
            <label for="validationCustom04" class="form-label">Galeria de imagenes</label>
  
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="imagenUno.png" aria-label="Resultados" aria-describedby="button-addon2">
              <div class="input-group-append" *ngIf="!isWatching">
                <button type="button" class="btn btn-outline-primary text-center"  (click)="addImage(settings.pictures.gallery)"><i class="fa fa-cloud-upload" aria-hidden="true"></i> Subir</button>
              </div>
            </div>
          </div>

          <div class="card mb-3">
            <div class="row p-2">
              <div class="col-md-6" *ngIf="picturesGallery.length == 0">
                <p >Este evento no tiene Imágenes en la Galería</p>
              </div>
              <div class="col-md-6" *ngFor="let picture of picturesGallery">
                <div class="input-group-append  float-right" *ngIf="!isWatching">
                  <button type="button" class="btn btn-link text-center"><i class="fa fa-times-circle-o text-dark"
                      aria-hidden="true" (click)="removePicture(picture, settings.pictures.gallery)"></i></button>
                </div>
                <img class="img-fluid img-thumbnail " width="100%"
                  onError="this.src='../../../assets/imgs/Default_Image_Thumbnail.png'"
                  [src]="filesUrl + picture" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group">
            <label for="validationCustom04" class="form-label">Imágenes Detalle</label>
  
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="fotoUno.png" aria-label="Resultados" aria-describedby="button-addon2">
              <div class="input-group-append" *ngIf="!isWatching">
                <button type="button" class="btn btn-outline-primary text-center"  (click)="addImage(settings.pictures.detail)"><i class="fa fa-cloud-upload" aria-hidden="true"></i> Subir</button>
              </div>
            </div>
          </div>

          <div class="card mb-3">
            <div class="row p-2">
              <div class="col-md-6" *ngIf="pictures.length == 0">
                <p >Este evento no tiene Imágenes Detalle</p>
              </div>
              <div class="col-md-6" *ngFor="let picture of pictures">
                <div class="input-group-append  float-right" *ngIf="!isWatching">
                  <button type="button" class="btn btn-link text-center"><i class="fa fa-times-circle-o text-dark"
                      aria-hidden="true" (click)="removePicture(picture, settings.pictures.detail)"></i></button>
                </div>
                <img class="img-fluid img-thumbnail"
                  onError="this.src='assets/imgs/Default_Image_Thumbnail.png'"
                  [src]="filesUrl + picture" />
              </div>
            </div>
          </div>
        </div>

      </div>
    </fieldset>

    <fieldset *ngIf="item?.user">
      <legend>Certificados</legend>
      <div class="form-row">

        <div class="form-group col-sm-4">
          <label for="validationCustom04" class="form-label">Resultados</label>

          <div class="input-group mb-3">
            <input type="text" class="form-control" aria-label="Resultados" aria-describedby="button-addon2" readonly formControlName="classifications">
            <div class="input-group-append" *ngIf="!isWatching">
              <button type="button" class="btn btn-outline-primary text-center" (click)="downloadClassifications()"><i class="fa fa-cloud-download" aria-hidden="true"></i> Descargar</button>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-4">
          <label for="validationCustom04" class="form-label">Certificado médico</label>

          <div class="input-group mb-3">
            <input type="text" class="form-control" aria-label="Resultados" aria-describedby="button-addon2" readonly formControlName="medicalCertificate">
            <div class="input-group-append" *ngIf="!isWatching">
              <button type="button" class="btn btn-outline-primary text-center" (click)="downloadMedicalCertificate()"><i class="fa fa-cloud-download" aria-hidden="true"></i> Descargar</button>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-4">
          <label for="validationCustom04" class="form-label">Deslinde de responsabilidad</label>

          <div class="input-group mb-3">
            <input type="text" class="form-control" aria-label="Resultados" aria-describedby="button-addon2" readonly formControlName="responsibilityDeployment">
            <div class="input-group-append" *ngIf="!isWatching">
              <button type="button" class="btn btn-outline-primary text-center" (click)="downloadResponsibilityDeployment()"><i class="fa fa-cloud-download" aria-hidden="true"></i> Descargar</button>
            </div>
          </div>
        </div>
        
      </div>
    </fieldset>

    <hr>

    <div class="row mb-5" *ngIf="creating && !isWatching">
      <div class="col-md-12">
        <button class="btn btn-info " type="button" (click)="recoveryPassword()">
          <i class="fa fa-lock" aria-hidden="true"></i>
          Restablecer contraseña
        </button>

        <button class="btn btn-primary float-right" type="submit" [disabled]="!form.valid">
          <i class="fa fa-check" aria-hidden="true"></i> GUARDAR
        </button>
      </div>
    </div>

    <div class="row mb-5" *ngIf="!creating && !isWatching">
      <div class="col-md-12">
        <button class="btn btn-primary float-right" type="submit" [disabled]="!form.valid">
          <i class="fa fa-check" aria-hidden="true"></i> GUARDAR
        </button>
      </div>
    </div>

  </form>
</div>