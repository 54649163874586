import { Component } from '@angular/core';
import { ItemComponent } from '../../core/item.component';
import { Validators } from '@angular/forms';
import { mailFormat } from '../../core/forms/validators/mailFormat';
import { Params } from '@angular/router';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['../../core/item.component.scss']
})
 
export class CodeComponent extends ItemComponent {
  
  getEndpoint() {
    return this.settings.endPoints.coupons;
  }

  getPopulates() {
    return [
      { "path": "event","populate": {"path": "user"}},
    ];
  }

  savePre(item) {
    item.event = item.event.id;
  }

  
  getFormNew(){
    return this.formBuilder.group({
      id: [null],
      organizer: [null, Validators.required],
      event: [null, Validators.required],
      discount: [null, Validators.required],
      quantity: [null, Validators.required],
      code: [null, Validators.required]
    })
    
  }

  getFormEdit(item){
    let organizer = item.event.user.firstName + item.event.user.lastName;

    return this.formBuilder.group({
      id: [item.id],
      organizer: [organizer, Validators.required],
      event: [item.event.name, Validators.required],
      discount: [item.discount, Validators.required],
      quantity: [item.quantity,Validators.required],
      code: [item.code, Validators.required]
    })
  }



}
