import { Component } from '@angular/core';
import { ItemsComponent } from '../../core/items.component';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['../../core/items.component.scss']
})
export class ActivitiesComponent extends ItemsComponent {

  groupId: any;
  group: any;


  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.groupId =params.id;

      this.getGroup(this.groupId);

      this.getItems();
    })

  }

  getPopulates() {
    return ['group'];
  }

  getEndPoint(){
    return this.settings.endPoints.trainings;
  }

  getFilters() {
    return {
      group: this.groupId
    };
  }


  getGroup(id){
    let endPoint = this.settings.endPoints.groups;

    this.pageService.httpGetById(id,[], endPoint)
    .then((res) =>{
      this.group = res.data;
    })
    .catch((err) => {
      console.log(err);
      this.pageService.showError(err);
    })
  }

  getFiltersSearch(textSearch) {

    let filtersSearch: any = {
      "$or": [
        { "description": { "$regex": textSearch, "$options": "i" } }
      ]
    };

    return filtersSearch;
  }

  handleEnable(training) {
    training.enabled = !training.enabled;
    const enabledAction = (training.enabled ? 'habilitado' : 'deshabilitado');

    let data = {
      id: training.id,
      enabled: training.enabled
    };
    this.pageService.httpUpdate(data, this.getEndPointUpdate())
      .then(res => {
        this.pageService.showSuccess('El evento ' + training.description + ' ha sido ' + enabledAction + ' correctamente!')
      })
      .catch(err => this.pageService.showError(err));
  }
  

  gotoInstitutionProfiles(){
    this.pageService.navigateRoute('institution-profiles')
  }

}
