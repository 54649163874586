import { Component } from '@angular/core';
import { ItemComponent } from '../../core/item.component';
import { Validators } from '@angular/forms';
import {mailFormat} from '../../core/forms/validators/mailFormat'
import { Params } from '@angular/router';

@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['../../core/item.component.scss']
})
export class AdministratorComponent extends ItemComponent {

  noLogged: boolean;
  adminId: string = '';
  process: boolean = false;

  initializePre() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.adminId = params.id;
    });
  }

  getFormNew() {
    return this.formBuilder.group({
      id: [null],
      emailAddress: [null, Validators.compose([Validators.required, mailFormat()])],

    });
  }

  getFormEdit(item) {    
    if(!item.emailAddress)  item.emailAddress = item.username;

    return this.formBuilder.group({
      id: [item.id],
      emailAddress: [item.emailAddress,Validators.compose([Validators.required, mailFormat()])],
    });
  }

  itemLoaded(item) {
      if(item) {
        if(item.id == this.pageService.global.user.id) this.noLogged = false;
        else if(item.id != this.pageService.global.user.id) this.noLogged = true;

      }
  }

  changePassword() {
    this.pageService.navigate('/edit/' + this.item.id + '/changePassword');
  }


  savePre(item) {
    item.username = item.emailAddress;
    item.password = item.emailAddress;
  }

  recoveryPassword() {
    this.process = true;
    let endPoint =this.pageService.global.settings.endPoints.administrators  + this.pageService.global.settings.endPoints.recoveryPassword + '/' + this.adminId;
    this.pageService.httpGetAll({}, {}, [], 1, [], endPoint)
    .then((res) => {
      this.pageService.showSuccess(res.message)
      this.process = false;

    })
    .catch((err) => {
      this.pageService.showError(err);
      this.process = false;
    })
  }



  onSubmitPerform(item) {
    this.savePre(item);

    if( !this.savePreCheck( item )) return;
    
    if (!item.id) {
      delete (item.id);
      this.pageService.httpCreate(item)
        .then((response) => {
          this.pageService.showSuccess('Se ha guardado exitosamente!!');
          this.savePost(response);
          this.pageService.navigate();
        })
        .catch((reason) => {
          this.pageService.showError(reason);
          this.savePostError(reason);
        });
    } else {
      this.pageService.httpUpdate(item)
        .then((response) => {
          this.pageService.showSuccess('Se ha actualizado exitosamente!!');
          this.savePost(response);
          this.pageService.navigate();
        })
        .catch((reason) => {
          this.pageService.showError(reason);
          this.savePostError(reason);
        });
    }
  }

}
