import { Component } from '@angular/core';
import { GlobalService } from './core/global.service';
import { RouterModule } from '@angular/router';
import { PageService } from './core/page.service';
import * as moment from 'moment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'adm';
  logged: boolean = false;
  user: any;
  settings: any;
  roles: any = ['administrator'];
  // Collapse variables
  showReportMenu: boolean = false;
  showGiftMenu: boolean = false;
  showCouponstMenu: boolean = false;
  showResellerstMenu: boolean = false;
  showUserstMenu: boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    public global: GlobalService,
    public pageService: PageService,
    public routerModule: RouterModule,
    private breakpointObserver: BreakpointObserver,
    public location: Location,
  ) {

    console.log("routerModule : ", routerModule);

    // Moment configuration
    moment.locale('es');

    // (+) User

    this.global.getUserAsObservable().subscribe((result) => {
      this.logged = result;
      this.user = this.global.getUser();
      let path = this.location.path();
      
      if (!this.logged) {
          this.pageService.navigateRoute("/login");

      } else {
        this.pageService.navigateRoute('/users');
      }

    });
    this.global.checkUser();

    // (-) User
    this.location.onUrlChange( () => {
      if (this.logged) {
        this.checkUser();
      }
    });
  }

  logout() {
    this.global.removeUser();
  }

  checkUser() {
    let endPoint= this.pageService.global.settings.endPoints.administrators;

    this.pageService.httpGetById(this.user.id, [], endPoint).then( (res) => {
      const user = res.data;
      if (!user.enabled) {
        this.pageService.showError('El usuario ha sido deshabilitado');
        this.logout();
      }
    })
    .catch( (error) => {
      // this.pageService.showError(error);
      console.log(error);
    });
  }

}
