<div class="container">
  <div class="row section-header">
    <div class="col-sm-6">
      <h2 class="section-title text-primary">Usuarios</h2>
    </div>
    <div class="col-sm-6">
      <button type="button" class="btn btn-outline-primary ml-2" (click)="export()">
        <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar
      </button>
    </div>
  </div>


  <div class="row mt-4 mb-4">

    <div class="input-group col-sm-6 mb-3">
      <input type="text" class="form-control" placeholder="Buscar" aria-label="Recipient's username"
        aria-describedby="button-addon2" [(ngModel)]="textSearch">
      <div class="input-group-append">
        <button class="btn btn-outline-primary" type="button" id="button-addon2" (click)="getItems()"><i class="fa fa-search"
            aria-hidden="true"></i></button>
      </div>
    </div>

  </div>

  <div class="row p-3">
    <div class="table-responsive">
      <table class="table">

        <thead class="thead-light">
          <tr>
            <th class="text-left align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center" (click)="sort('firstName')">
                Nombre
                <i class="fa pl-2" [ngClass]="{'fa-arrow-down': sortField.firstName == 1,'fa-arrow-up': sortField.firstName == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>
            <th class="text-left align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center" (click)="sort('lastName')">
                Apellido
                <i class="fa pl-2"
                  [ngClass]="{'fa-arrow-down': sortField.lastName == 1,'fa-arrow-up': sortField.lastName == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>
            <th class="text-left align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center" (click)="sort('emailAddress')">
                E-mail
                <i class="fa pl-2" [ngClass]="{'fa-arrow-down': sortField.emailAddress == 1,'fa-arrow-up': sortField.emailAddress == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <!-- Cargador -->
          <tr *ngIf="loading" class="text-center">
            <td colspan="6">
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Cargando...
            </td>
          </tr>
          <!-- Texto cuando no hay resultados  -->
          <tr *ngIf="!loading && items.length == 0" class="text-center">
            <td colspan="6">
              <span class="text-muted">
                <i class="fa fa-close" aria-hidden="true"></i>
                No hay resultados para mostrar
              </span>
            </td>
          </tr>

          <tr *ngFor="let item of items">
            <td class="px-4 text-left clickable" (click)="goWatch(item)">
              {{ item.firstName }}
              <mat-icon class="lock-icon" *ngIf="!item.enabled">lock</mat-icon>
            </td>
            <td class="px-4 text-left">
              {{ item.lastName }}
            </td>
            <td class="px-4 text-left">
              {{ item.emailAddress }}
            </td>


            <td class="text-right">
              <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="goEdit(item)">
                  <mat-icon>create</mat-icon>Editar
                </button>
                <button mat-menu-item (click)="handleEnable(item)">
                  <mat-icon>{{item.enabled? 'lock' : 'lock_open'}}</mat-icon>{{item.enabled? 'Deshabilitar' : 'Habilitar'}}
                </button>
              </mat-menu>
            </td>
          </tr>

        </tbody>

      </table>
    </div>
  </div>

  <div class="row">
    <div class="col-4 offset-4 d-flex justify-content-center">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{'disabled': page <= 1}"><a class="page-link"
              (click)="previousPage()">Anterior</a></li>
          <li class="page-item "><a class="page-link">{{page}}</a></li>
          <li class="page-item" [ngClass]="{'disabled': pages <= page}"><a class="page-link"
              (click)="nextPage()">Siguiente</a></li>
        </ul>
      </nav>
    </div>
    <div class="col-md-4 text-md-right text-center p-2">
      <span class="text-muted">
        Total de páginas {{pages}}
      </span>
    </div>
  </div>

</div>