<div class="container">
  <nav aria-label="breadcrumb" class="mt-2">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/activities/'+item?.group]" routerLinkActive="router-link-active">Actividades</a></li>
      <li class="breadcrumb-item active" aria-current="page">Modificar actividad</li>
    </ol>
  </nav>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="needs-validation">
    
    <div class="form-row">
      <div class="col-md-6 mt-4">
        <fieldset>
          <legend>Itinerario</legend>
          <div class="form-group">
            <label>Nombre</label>
            <input type="text" class="form-control" formControlName="name" [readonly]="isWatching" placeholder="Entrenamiento uno">
            <small *ngIf="!isFieldValid('name')" class="form-text text-danger">
              {{getFieldError('name')}}
            </small>
          </div>

          <div class="form-group">
            <label>Fecha</label>
            <input type="date" class="form-control" [readonly]="isWatching" placeholder="3/11/1996" formControlName="date">
            <small *ngIf="!isFieldValid('date')" class="form-text text-danger">
              {{getFieldError('date')}}
            </small>
          </div>

          <div class="form-group">
            <label for="exampleFormControlInput1">Horario</label>
            <input type="time" class="form-control" id="exampleFormControlInput1"  formControlName="schedule" name="eta" [readonly]="isWatching" >
            <small *ngIf="!isFieldValid('schedule')" class="form-text text-danger">
              {{getFieldError('schedule')}}
            </small>
          </div>

          <div class="form-group">
            <label for="exampleFormControlTextarea1">Descripción</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="10 vueltas al parque" formControlName="description" [readonly]="isWatching"></textarea>
            <small *ngIf="!isFieldValid('description')" class="form-text text-danger">
              {{getFieldError('description')}}
            </small>
          </div>

        </fieldset>
      </div>

      <div class="col-md-6 mt-4">
        <fieldset>
          <legend>Dirección</legend>
          
          <div class="form-group">
            <div class="form-group">
              <app-map [latitude]="latitude" [longitude]="longitude" [address]="address" [addressGoogle]="addressGoogle"
                (newAddress)="setNewAddress($event)" (newAddressGoogle)="setNewAddressGoogle($event)"
                (newLatitude)="setNewLatitude($event)" (newLongitude)="setNewLongitude($event)" [edit]="true"
                [isWatching]=isWatching>
              </app-map>
            </div>
          </div>
        </fieldset>
      </div>

    </div>

    <hr>
    
    <!-- <div class="row mb-5" *ngIf="creating && !isWatching">
      <div class="col-md-12">
          <button  class="btn btn-info " type="button" (click)="recoveryPassword()" >
            <i class="fa fa-lock" aria-hidden="true"></i>
            Restablecer contraseña
          </button>

        <button class="btn btn-primary float-right" type="submit" [disabled]="!form.valid">
          <i class="fa fa-check" aria-hidden="true"></i> GUARDAR
        </button>
      </div>
    </div> -->

    <div class="row mb-5" *ngIf="!creating && !isWatching">
      <div class="col-md-12">
        <button class="btn btn-primary float-right" type="submit" [disabled]="!form.valid" >
          <i class="fa fa-check" aria-hidden="true"></i> GUARDAR
        </button>
      </div>
    </div>

  </form>
</div>

