<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
    <div class="logo-container">
      <img src="assets/imgs/Moment_logo.png">
    </div>
    <!-- <mat-toolbar mat-list-item><img src="assets/imgs/logo.png" class="img-toolbar"></mat-toolbar> -->
    <mat-nav-list class="global-mat-nav">
      <ng-container *ngIf="logged">

        <a mat-list-item [routerLink]="['/events']" routerLinkActive="router-link-active">
          <mat-icon mat-list-icon>event_note</mat-icon>
          <h3 mat-line>
            Eventos
          </h3>
        </a>


        <a mat-list-item [routerLink]="['/race-types']" routerLinkActive="router-link-active">
          <mat-icon mat-list-icon>date_range</mat-icon>
          <h3 mat-line>
            Tipos de eventos
          </h3>
        </a>

        <a mat-list-item [routerLink]="['/administrators']" routerLinkActive="router-link-active">
          <mat-icon mat-list-icon>people_outline</mat-icon>
          <h3 mat-line>
            Administradores
          </h3>
        </a>

        <a mat-list-item [routerLink]="['/users']" routerLinkActive="router-link-active">
          <mat-icon mat-list-icon>account_box</mat-icon>
          <h3 mat-line>
            Usuarios
          </h3>
        </a>

        <a mat-list-item [routerLink]="['/organizators']" routerLinkActive="router-link-active">
          <mat-icon mat-list-icon>group</mat-icon>
          <h3 mat-line>
            Organizadores
          </h3>
        </a>

        <a mat-list-item [routerLink]="['/groups']" routerLinkActive="router-link-active">
          <mat-icon mat-list-icon>sports</mat-icon>
          <h3 mat-line>
            Grupos de entrenamientos
          </h3>
        </a>

        <a mat-list-item [routerLink]="['/codes']" routerLinkActive="router-link-active">
          <mat-icon mat-list-icon>money</mat-icon>
          <h3 mat-line>
            Codigos
          </h3>
        </a>

        <a mat-list-item [routerLink]="['/sliders']" routerLinkActive="router-link-active">
          <mat-icon mat-list-icon>photo_library</mat-icon>
          <h3 mat-line>
            Slider home
          </h3>
        </a>

        <a mat-list-item [routerLink]="['/sponsors']" routerLinkActive="router-link-active">
          <mat-icon mat-list-icon>redeem</mat-icon>
          <h3 mat-line>
            Sponsors
          </h3>
        </a>

      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="menubar">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="d-none d-md-inline">Movent CMS</span>
      <span class="example-spacer"></span>
      <button type="button" class="btn btn-outline-default" *ngIf="user">
        <i class="fa fa-user-circle-o mr-2"></i>{{user?.username}}
      </button>
      <button type="button" class="btn btn-outline-dark" (click)="logout()" *ngIf="user">
        Cerrar sesión<i class="fa fa-sign-out ml-2"></i>
      </button>
    </mat-toolbar>
    <!--The content below is only a placeholder and can be replaced.-->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>