<div class="container">
  <nav aria-label="breadcrumb" class="mt-2">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/groups']" routerLinkActive="router-link-active">Grupos</a></li>
      <li class="breadcrumb-item active" aria-current="page">Crear grupo</li>
    </ol>
  </nav>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <div class="form-row">
      <div class="col-sm-6">

        <div class="card mb-3">
          <div class="row p-2">
            <div class="col-md-4">
              <img class="img-fluid img-thumbnail" onError="this.src='assets/imgs/Default_Image_Thumbnail.png'"
                [src]="filesUrl + form?.value?.picture" />
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title">Foto del grupo</h5>
                <div class="" *ngIf="!isWatching">
                  <button type="button" class="btn btn-outline-primary text-center"><i
                      class="fa fa-cloud-upload" aria-hidden="true"></i> Subir foto</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Nombre</label>
          <input type="text" class="form-control" formControlName="name" [readonly]="isWatching"
            placeholder="Grupo1">
          <!-- <small *ngIf="!isFieldValid('name')" class="form-text text-danger">
            {{getFieldError('name')}}
          </small> -->
        </div>

        <div class="form-group">
          <label for="exampleFormControlTextarea1">Descripción</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" [readonly]="isWatching" formControlName="description"></textarea>
        </div>

        <div class="form-group">
          <label>Actividad principal</label>
          <input type="text" class="form-control" [readonly]="isWatching" formControlName="category"
            placeholder="Running">
          <!-- <small *ngIf="!isFieldValid('firstName')" class="form-text text-danger">
            {{getFieldError('firstName')}}
          </small> -->
        </div>


      </div>

      <div class="col-sm-6">
        <div class="form-group">
          <app-map [latitude]="latitude" [longitude]="longitude" [address]="address" [addressGoogle]="addressGoogle"
            (newAddress)="setNewAddress($event)" (newAddressGoogle)="setNewAddressGoogle($event)"
            (newLatitude)="setNewLatitude($event)" (newLongitude)="setNewLongitude($event)" [edit]="true"
            [isWatching]=isWatching>
          </app-map>
        </div>
      </div>

    </div>

    <hr>

    <div class="row mb-5" *ngIf="!creating && !isWatching">
      <div class="col-md-12">
        <button class="btn btn-primary float-right" type="submit" [disabled]="!form.valid">
          <i class="fa fa-check" aria-hidden="true"></i> GUARDAR
        </button>
      </div>
    </div>

    <div class="row mb-5" *ngIf="creating && !isWatching">
      <div class="col-md-12">
        <button class="btn btn-primary float-right" type="submit" [disabled]="!form.valid">
          <i class="fa fa-check" aria-hidden="true"></i> GUARDAR
        </button>
      </div>
    </div>


  </form>
</div>