import { Component } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
import * as moment from 'moment';
import { ItemsComponent } from '../../core/items.component';

@Component({
  selector: 'app-enrolled',
  templateUrl: './enrolled.component.html',
  styleUrls: ['../../core/items.component.scss']
})
export class EnrolledComponent extends ItemsComponent {

  name: string;
  picture: string;
  creating = false;
  disabled = false;
  //======================================================
  eventId: any;
  event: any;
  search: string = null;
  itemsFormat: [];
  options = { 
    filename:'Inscripciones',
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true, 
    showTitle: true,
    title: 'Inscripciones',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Nombre', 'Apellido', 'E-mail', 'Cuil', 'Habilitado', 'Registro']
  };

  initialize() {
    this.activatedRoute.params.subscribe((params) => {
      this.eventId = params.id;
      this.getEvent( this.eventId );
    })
  }

  getEndPoint(){
    return this.settings.endPoints.inscriptions;
  }

  getFilters() {
    return {
      event: this.eventId,
      status: this.settings.inscriptions.status.payed.code
    };
  }

  getEvent(id) {
    let endPoint = this.settings.endPoints.events;
    this.pageService.httpGetById(id, ['user'], endPoint)
    .then((res) => {
     this.event = res.data;
    })
    .catch((err) => {
      console.log(err);
      this.pageService.showError(err);
    })
  }

  getPopulates() {
    return [ 'user', 'raceCategory' ];
  }


  searchInscriptions() {
    if(!this.search) return this.getItems();

    else {
       let endPoint = this.settings.endPoints.inscriptions + this.settings.endPointsMethods.inscriptions.searchInscriptions + "/" + this.search + "/" + this.eventId;

       this.pageService.httpSimpleGetAll(endPoint)
       .then((res) => {
           this.items = res.data;
       })
       .catch((err) => {
         console.log(err);
         this.pageService.showError(err);
       })
    }
  }

  itemsFormatted() {
  
    this.itemsFormat = this.items.map((item) => {
         let itemToReturn = {
             NumeroCorredor: item.runnerNumber,
             Apellido: item.user.lastName || '',
             Nombre: item.user.firstName || '',
             Categoria: item.raceCategory.name || '',
             Telefono: item.user.phoneNumber || '',
             Sexo: item.user.gender === 'male' ? 'Masculino' : 'Femenino' || '',
             Email: item.user.emailAddress || '',
             DNI: item.user.dni || '',
             FechaDeNacimiento: moment(item.user.birthDate).format('DD/MM/YY') || '',
             TalleDeRemera: item.user.tShirtSize || '',
             EsCeliaco: item.user.isCeliac || '',
             NombreContactoEmergencia: item.user.emergencyContactName || '',
             TelefonoContactoEmergencia: item.user.emergencyContactPhoneNumber || '',
             Direccion: item.user.address || '',
             Ciudad: item.user.locality || '',
             Provincia: item.user.province || '',
             Pais: item.user.country || ''
         }

         return itemToReturn
     })
   }

  donwload() {

    if(this.items.length == 0 ) return this.pageService.showError('La lista está vacía');
    
    else {
      this.itemsFormatted();

      const csvExporter = new ExportToCsv(this.options);

      csvExporter.generateCsv(this.itemsFormat);
    }

  }




handleEnable(inscription) {
  inscription.enabled = !inscription.enabled;

  const enabledAction = (inscription.enabled ? 'habilitado' : 'deshabilitado');

  inscription.user = inscription.user.id;

  this.pageService.httpUpdate(inscription, this.settings.endPoints.inscriptions)
    .then(res => {
      this.pageService.showSuccess('La inscripcion ha sido ' + enabledAction + ' correctamente!');
      this.getItems();
    })
    .catch(err => {
      this.pageService.showError(err);
      this.getItems();
    });
}
//============================================================================================================================


  watchUsuario(data){
    this.pageService.navigateRoute('/users/watch/' + this.event.user.id)
  }

}
