<div class="container">
  <div class="row section-header">
    <div class="col-sm-6">
      <h2 class="section-title text-primary">Eventos</h2>
    </div>
    <div class="col-sm-6">
      <button type="button" class="btn btn-primary ml-2" (click)="create()">
        <i class="fa fa-plus"></i> Crear
      </button>
    </div>
  </div>

  <div class="form-row mt-4 mb-4">

    <div class="form-group col-md-4">
      <input type="text" class="form-control" placeholder="País" aria-label="Recipient's username"
      aria-describedby="button-addon2" [(ngModel)]="textSearchCountry">
    </div>

    <div class="form-group col-md-4">
      <input type="text" class="form-control" placeholder="Provincia" aria-label="Recipient's username"
      aria-describedby="button-addon2" [(ngModel)]="textSearchProvince">
    </div>

    <div class="form-group col-md-4">
      <select class="custom-select" id="inputGroupSelect04" [(ngModel)]="selectedCategory" >
        <option [selected]="true" value="">Tipo de evento</option>
        <option *ngFor="let category of categories" [value]="category.id" >{{category.name}}</option>
      </select>
    </div>

  </div>

  <div class="form-row align-items-end mb-4">

    <div class="form-group col-md-3">
      <input type="text" class="form-control" placeholder="Buscar" aria-label="Recipient's username"
        aria-describedby="button-addon2" [(ngModel)]="textSearch">
    </div>

    <div class="form-group col-md-3">
      <label>Desde:</label>
      <input type="date" class="form-control" placeholder="3/11/1996" [(ngModel)]="dateFrom">
    </div>

    <div class="form-group col-md-3">
      <label>Hasta:</label>
      <input type="date" class="form-control" placeholder="3/11/1996" [(ngModel)]="dateTo">
    </div>

    <div class="form-group col-md-3">
      <button class="btn btn-outline-primary float-right" type="button" id="button-addon2" (click)="getItems()">
        <i class="fa fa-search" aria-hidden="true"></i> Buscar
      </button>
    </div>

  </div>

  <div class="row p-3">
    <div class="table-responsive">
      <table class="table">

        <thead class="thead-light">
          <tr>

            <th class="text-center align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center" (click)="sort('enabled')">
                Dectacados
                <i class="fa pl-2"
                  [ngClass]="{'fa-arrow-down': sortField.enabled == 1,'fa-arrow-up': sortField.enabled == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>

            <th class="text-left align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center m-auto" (click)="sort('name')">
                Nombre
                <i class="fa pl-2" [ngClass]="{'fa-arrow-down': sortField.name == 1,'fa-arrow-up': sortField.name == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>
            <th class="text-left align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center m-auto" (click)="sort('organizer')" disabled>
                Organizador
                <i class="fa pl-2"
                  [ngClass]="{'fa-arrow-down': sortField.organizer == 1,'fa-arrow-up': sortField.organizer == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>
            <th class="text-left align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center m-auto" (click)="sort('address')">
                Lugar
                <i class="fa pl-2" [ngClass]="{'fa-arrow-down': sortField.address == 1,'fa-arrow-up': sortField.address == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>
            <th class="text-left align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center m-auto" (click)="sort('date')">
                Fecha - Hora
                <i class="fa pl-2" [ngClass]="{'fa-arrow-down': sortField.date == 1,'fa-arrow-up': sortField.date == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>
            <th class="text-left align-middle">
              <button type="button" class="btn btn-light d-flex align-items-center m-auto" (click)="sort('typeRace')" disabled>
                Tipo de carrera
                <i class="fa pl-2"
                  [ngClass]="{'fa-arrow-down': sortField.typeRace == 1,'fa-arrow-up': sortField.typeRace == -1}"
                  aria-hidden="true"></i>
              </button>
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <!-- Cargador -->
          <tr *ngIf="loading" class="text-center">
            <td colspan="6">
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Cargando...
            </td>
          </tr>
          <!-- Texto cuando no hay resultados  -->
          <tr *ngIf="!loading && items.length == 0" class="text-center">
            <td colspan="6">
              <span class="text-muted">
                <i class="fa fa-close" aria-hidden="true"></i>
                No hay resultados para mostrar
              </span>
            </td>
          </tr>

          <tr *ngFor="let item of items">
            <td class="px-4 text-center">
              <i class="fa fa-square-o fa-2x" *ngIf="!item.featured" (click)="setFeatured(item)" role="button"></i>
              <i class="fa fa-check-square-o fa-2x" *ngIf="item.featured" (click)="setFeatured(item)" role="button"></i>
            </td>
            <td class="px-4 text-left clickable" (click)="watch(item)">
              {{ item.name }}
              <mat-icon class="lock-icon" *ngIf="!item.enabled">lock</mat-icon>
            </td>
            <td class="px-4 text-left" *ngIf="item?.user">
              {{ item.user.firstName + ' ' + item.user.lastName }}
            </td>
            <td class="px-4 text-left" *ngIf="item?.administrator">
              {{ item.administrator.username }}
            </td>
            <td class="px-4 text-left">
              {{ item.address }}
            </td>
            <td class="px-4 text-left">
              {{ item.date | date: 'dd/MM/yy' }}
              <br>
              {{ item.schedule | date: 'hh:mm' }}
            </td>
            <td class="px-4 text-left">
              {{ item.category.name }}
            </td>


            <td class="text-right">
              <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="edit(item)">
                  <mat-icon>create</mat-icon>Editar
                </button>
                <button mat-menu-item (click)="goToEnrrolled(item.id)" *ngIf="!item.administrator">
                  <mat-icon>group</mat-icon>Inscriptos
                </button>
                <button mat-menu-item (click)="handleEnable(item)">
                  <mat-icon>{{item.enabled? 'lock' : 'lock_open'}}</mat-icon>{{item.enabled? 'Deshabilitar' : 'Habilitar'}}
                </button>
              </mat-menu>
            </td>
          </tr>

        </tbody>

      </table>
    </div>
  </div>

  <div class="row">
    <div class="col-4 offset-4 d-flex justify-content-center">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{'disabled': page <= 1}"><a class="page-link"
              (click)="previousPage()">Anterior</a></li>
          <li class="page-item "><a class="page-link">{{page}}</a></li>
          <li class="page-item" [ngClass]="{'disabled': pages <= page}"><a class="page-link"
              (click)="nextPage()">Siguiente</a></li>
        </ul>
      </nav>
    </div>
    <div class="col-md-4 text-md-right text-center p-2">
      <span class="text-muted">
        Total de páginas {{pages}}
      </span>
    </div>
  </div>

</div>