import { Component } from '@angular/core';
import { ItemComponent } from '../../core/item.component';
import { Validators } from '@angular/forms';
import { mailFormat } from '../../core/forms/validators/mailFormat';
import * as moment from 'moment';

@Component({
  selector: 'app-organizator',
  templateUrl: './organizator.component.html',
  styleUrls: ['../../core/item.component.scss']
})

export class OrganizatorComponent extends ItemComponent {
  

  creating = false;
  disabled = false;
  name: string;
  picture: string;
  
  endPoint = this.settings.endPoints.users;
  address: any;
  addressGoogle: any;
  latitude: any;
  longitude: any;
  
  dni: any;
  
  categories: any = [];
  date: any
  defaultDate: any;
  idsCategories: any = [];
  image:any = "";
  isCeliac: boolean;
  isOrganizator: boolean;
  selectedCategories: any = [];
  isUserRoute: boolean;

  initializePre() {
    let route = (this.pageService.router.url.split('/'));
    this.isUserRoute = route[1] === 'users' ? true : false;
  }

  itemLoaded(item){
    this.selectedCategories = [];
    this.getCategories();

    if (item) {
      this.address = item.address;
      this.addressGoogle = item.addressGoogle;
      this.longitude = item.location.coordinates[0];
      this.latitude = item.location.coordinates[1];
      this.date = moment(item.birthDate).format('YYYY-MM-DD');
    }

    this.isOrganizator = item.roles.includes('organizator') ? true : false;
    this.isCeliac = item.isCeliac;
  }

  getEndPoint(){
    return this.settings.endPoints.users;
  }

  getEndPointLoad() {
    return this.pageService.global.settings.endPoints.users;
  }

  getEndPointUpdate() {
    return this.pageService.global.settings.endPoints.users;
  }

  getCategories(){
    const endPoint = this.settings.endPoints.categories;
    const filter = { enabled: true };
    this.pageService.httpGetAll( filter, {name: 1}, [], 1, [], endPoint )
      .then( res => {
        this.categories = res.data;
        this.selectedCategories = [];
        for(let category of this.categories){
          if(this.item.categories.includes(category.id))  this.selectedCategories.push(category)
        }
        this.saveIdsCategories();
      })
      .catch( err => {
        this.pageService.showError(err);
      })
  }

  setNewAddress(newAddress) {
    this.address = newAddress;
  }

  setNewLatitude(newLatitude) {
    this.latitude = newLatitude;
  }

  setNewLongitude(newLongitude) {
    this.longitude = newLongitude;
  }

  setNewAddressGoogle(newAddressGoogle) {
    this.addressGoogle = newAddressGoogle;
  }

  getFormNew(){
    return this.formBuilder.group({

      gender: [null, Validators.required],
      tShirtSize: [null, Validators.required],
      categories: [null, Validators.compose([Validators.required, Validators.minLength(1)])],
      commissionPercentage: [null],
      dni: [null, Validators.required],
      emailAddress: [null, Validators.compose([Validators.required, Validators.email, mailFormat()])],
      emergencyContactName: [null, Validators.required],
      emergencyContactPhoneNumber: [null, Validators.required],
      firstName: [null, Validators.required],
      isCeliac: [null, Validators.required],
      kilometersTraveled: [null, Validators.min(0)],
      lastName: [null, Validators.required],
      location: [null],
      phoneNumber: [null],
      picture: [null],
      roles: [null],
      address: [null],
      addressGoogle: [null],
      birthDate: [null],
    })
  }

  getFormEdit(item){
    return this.formBuilder.group({
      id: [item.id],
      categories: [item.categories, Validators.compose([Validators.required, Validators.minLength(1)])],
      commissionPercentage: [item.commissionPercentage],
      dni: [item.dni, Validators.required],
      emailAddress: [item.emailAddress, Validators.compose([Validators.required, Validators.email, mailFormat()])],
      emergencyContactName: [item.emergencyContactName, Validators.required],
      emergencyContactPhoneNumber: [item.emergencyContactPhoneNumber, Validators.required],
      firstName: [item.firstName, Validators.required],
      isCeliac: [item.isCeliac, Validators.required],
      kilometersTraveled: [item.kilometersTraveled, Validators.min(0)],
      lastName: [item.lastName, Validators.required],
      gender: [item.gender, Validators.required],
      roles: [item.roles, Validators.required],
      tShirtSize: [item.tShirtSize, Validators.required],
      phoneNumber: [item.phoneNumber],
      location: [item.location],
      picture: [item.picture],
      address: [item.address],
      addressGoogle: [item.addressGoogle],
      birthDate: [item.birthDate],
    })
  }

  savePre(item){
    const location = {
      type:'Point',
      coordinates: [this.longitude, this.latitude]
    };
    item.address = this.address;
    item.addressGoogle = this.addressGoogle;
    item.location = location;

    if(!item.roles.includes('organizator')) item.commissionPercentage = 0;
  }

  savePreCheck( item ) {
    if(item.roles.includes('organizator') && (item.commissionPercentage === null || item.commissionPercentage < 1 || item.commissionPercentage > 99)) {
      this.pageService.showError('Ingrese un porcentaje de comisión válido');
      return false;
    }
    else {
      return true;
    }
  }
  
  savePost(item){
    this.formReset();
  }

  changeImage() {
    this.pageService.showImageUpload()
      .then((resp: any) => {
        this.image = resp.data.file;
        this.form.patchValue({ picture: resp.data.file });
      })
      .catch((error: any) => { });
  }

  removeCategory(category: any){
    this.selectedCategories = this.selectedCategories.filter( cat => cat !== category);
    this.saveIdsCategories();
  }

  recoveryPassword() {
    if (!confirm('¿Está seguro que desea restablecer esta contraseña?. La nueva contraseña será enviada a: ' + this.item.emailAddress)) {
      return;
    }
    const endPoint = this.settings.endPoints.users + this.settings.endPointsMethods.users.recoveryPassword + '/' + this.item.username;

    this.pageService.httpGetAll({}, {}, [], 1, {}, endPoint)
    .then( (res) => {
      this.pageService.showSuccess(res.message);
    })
    .catch((err) => {
      this.pageService.showError(err);
    });
  }

  openModal(content, item?, disabled?, buttonType?) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.clearVariables();
    }, (reason) => {
      this.closeResult = `Dismissed`;
      this.clearVariables();
    });
  }

  clearVariables() {
    this.name = undefined;
    this.picture = undefined;
    this.dni = undefined;
  }
  
  closeModal() {
    this.modalService.dismissAll();
  }

  saveItemModal() {
    this.saveIdsCategories();
    this.closeModal();
  }

  saveIdsCategories(){
    this.idsCategories = [];
    for(let category of this.selectedCategories){
      this.idsCategories.push(category.id);
    }
    this.form.patchValue({categories: this.idsCategories});
  }

  onChangeCheck(event:any, category: any){
    event.target.checked ? this.selectedCategories.push(category)
      : this.selectedCategories = this.selectedCategories.filter( cat => cat !== category);
  }

  onChangeIsOrganizator(event){
    let roles;
    if(event.target.value == 'si')  roles = ['user', 'organizator'];
    if(event.target.value == 'no')  roles = ['user'];

    this.form.patchValue({ roles: roles });
  }

  onChangeIsCeliac(event){
    let isCeliac;
    if(event.target.value == 'si')  isCeliac = true;
    if(event.target.value == 'no')  isCeliac = false;

    this.form.patchValue({ isCeliac: isCeliac });
  }

  onChangeDate(){
    this.form.patchValue({
      date: this.date
    });
  }

  onSubmitPre(){
    if(this.selectedCategories > 0){
      this.pageService.showError("Seleccione al menos una categoría");
    }
    this.saveIdsCategories();
    this.onSubmit();
  }
}
