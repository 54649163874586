<div class="container">
  <nav aria-label="breadcrumb" class="mt-2">
    <ol class="breadcrumb">
      <li *ngIf="!params.type || params.type == '2'" class="breadcrumb-item">
        <a [routerLink]="['/sponsors']" routerLinkActive="router-link-active">Sponsors</a>
      </li>

      <li class="breadcrumb-item active" aria-current="page">Agregar sponsor</li>
    </ol>
  </nav>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="needs-validation">

    <div class="form-row">
      <div class="col-md-6 mt-4">

        <div class="form-group">
          <label for="validationCustom04" class="form-label">Imagen</label>
        </div>

        <div class="card mb-3">
          <div class="form-group" *ngIf="!isWatching && this.form.value.picture">
            <button type="button" class="btn btn-link text-center float-right" (click)="removePicture()"><i class="fa fa-trash text-dark"
                aria-hidden="true"></i></button>
          </div>
          <img class="img-fluid img-thumbnail" onError="this.src='../../../assets/imgs/Default_Image_Thumbnail.png'"
            [src]="filesUrl + form?.value?.picture" />
        </div>

        <div class="form-group text-center" *ngIf="!isWatching">
          <div class="input-group mb-3">
            <div class="input-group-append">
              <button type="button" class="btn btn-outline-primary text-center" (click)="changeImage()"><i class="fa fa-cloud-upload" aria-hidden="true"></i> Subir</button>
            </div>
          </div>
        </div>
  
        <div class="form-group">
          <label>Nombre</label>
          <input type="text" class="form-control" [ngClass]="{'is-invalid': !isFieldValid('firstName')}"
            formControlName="name" [readonly]="isWatching" placeholder="Sponsor">
          <small *ngIf="!isFieldValid('name')" class="form-text text-danger">
            {{getFieldError('name')}}
          </small>
        </div>

      </div>

    </div>

    <hr>

    <div class="row mb-5" *ngIf="creating && !isWatching">
      <div class="col-md-12">

        <button class="btn btn-primary float-right" type="submit" [disabled]="!form.valid">
          <i class="fa fa-check" aria-hidden="true"></i> GUARDAR
        </button>
      </div>
    </div>

    <div class="row mb-5" *ngIf="!creating && !isWatching">
      <div class="col-md-12">
        <button class="btn btn-primary float-right" type="submit" [disabled]="!form.valid">
          <i class="fa fa-check" aria-hidden="true"></i> GUARDAR
        </button>
      </div>
    </div>

  </form>
</div>