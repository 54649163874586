<div class="container-fluid d-flex align-items-center h-100 bg-dark">
  <div class="col-md-8 offset-md-2">
    <div class="card">
      <div class="card-body p-0 gradient-bg">
        <div class="row m-0">
          <div class="col-md-6">
            <div class="row h-100">
              <div class="col-8 offset-2 align-items-center d-flex">
                <img src="assets/imgs/Moment_logo.png" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="col-md-6 bg-light">
            <form class="w-100 pt-3 container" [formGroup]="form" (ngSubmit)="login(form.value)">
              <!-- <div class="form-group mb-3">
                <label>Tipo</label>
                <select class="form-control" formControlName="role">
                  <option value=null>--Seleccione tipo--</option>
                  <option value="administrators">Administrador</option>
                  <option value="companies">Empresa</option>
                </select>
              </div> -->

              <div class="form-group mb-3">
                <label>Usuario</label>
                <input type="text" class="form-control" formControlName="username">
              </div>
              <div class="form-group mb-3">
                <label>Contraseña</label>
                <input type="password" class="form-control" formControlName="password">
              </div>
              <button class="btn btn-primary btn-block mb-4 mt-4" block [disabled]="!form.valid" type="submit">
                <i class="fa fa-check" aria-hidden="true"></i> INGRESAR
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>