import { Component } from '@angular/core';
import { ItemsComponent } from '../../core/items.component';

@Component({
  selector: 'app-organizators',
  templateUrl: './organizators.component.html',
  styleUrls: ['../../core/items.component.scss']
})
export class OrganizatorsComponent extends ItemsComponent {

  creating = false;
  disabled = false;

  getEndPoint(){
    return this.settings.endPoints.users;
  }
  
  handleEnable(user) {
    if(confirm('¿Desea quitarle el permiso de organizador a este usuario?')){
      user.roles = user.roles.includes('organizator') ? ['user'] : ['user', 'organizator'];

      this.pageService.httpUpdate(user, this.getEndPoint())
        .then(res => {
          this.pageService.showSuccess('El usuario ' + user.firstName + ' ' + user.lastName + ' ya no es organizador');
          this.getItems();
        })
        .catch(err => this.pageService.showError(err));
    }
  }
  
  getFilters() {
    let _filters = {
      roles: 'organizator'
    };

    return _filters;
  }

  getFiltersSearch(textSearch) {

    let filtersSearch: any = {
      "$or": [
        { "firstName": { "$regex": textSearch, "$options": "i" } },
        { "lastName": { "$regex": textSearch, "$options": "i" } }
      ]
    };
    
    return filtersSearch;
  }
}
