import { Component } from '@angular/core';
import { ItemsComponent } from '../../core/items.component';

@Component({
  selector: 'app-codes',
  templateUrl: './codes.component.html',
  styleUrls: ['../../core/items.component.scss']
})
export class CodesComponent extends ItemsComponent {
  
 
  getEndPoint(){
    return this.settings.endPoints.coupons;
  }

  getPopulates() {
    return ['event'];
  }
  
}
