<div class="container">
  <nav aria-label="breadcrumb" class="mt-2">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/codes']" routerLinkActive="router-link-active">Código</a></li>
      <li class="breadcrumb-item active" aria-current="page">Modificar códigos de descuento</li>
    </ol>
  </nav>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <div class="form-row">
      <div class="col-sm-6">

        <div class="form-group">
          <label>Organizador</label>
          <input type="text" class="form-control" formControlName="organizer" disabled
            placeholder="María del Carmen">
          <small *ngIf="!isFieldValid('organizer')" class="form-text text-danger">
            {{getFieldError('organizer')}}
          </small>
        </div>
        
        <div class="form-group">
          <label>Evento</label>
          <input type="text" class="form-control" formControlName="event" disabled
            placeholder="Evento uno">
          <small *ngIf="!isFieldValid('event')" class="form-text text-danger">
            {{getFieldError('event')}}
          </small>
        </div>

        <div class="form-group">
          <label>Descuento(%)</label>
          <input type="number" class="form-control" formControlName="discount" [readonly]="isWatching"
            placeholder="15%">
          <small *ngIf="!isFieldValid('discount')" class="form-text text-danger">
            {{getFieldError('discount')}}
          </small>
        </div>

        <div class="form-group">
          <label>Cantidad</label>
          <input type="number" class="form-control" formControlName="quantity" disabled
            placeholder="10">
          <small *ngIf="!isFieldValid('quantity')" class="form-text text-danger">
            {{getFieldError('quantity')}}
          </small>
        </div>

        <div class="form-group">
          <label>Código</label>
          <input type="text" class="form-control" formControlName="code" disabled
            placeholder="Evento uno">
          <small *ngIf="!isFieldValid('code')" class="form-text text-danger">
            {{getFieldError('code')}}
          </small>
        </div>

      </div>
    </div>

    <hr>

    <div class="row mb-5">
      
      <div class="col-md-6" *ngIf="!isWatching">
        <button class="btn btn-primary float-right" type="submit" [disabled]="!form.valid">
          <i class="fa fa-check" aria-hidden="true"></i> GUARDAR
        </button>
      </div>

    </div>
  </form>
</div>