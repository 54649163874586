import { Component } from '@angular/core';
import { ItemsComponent } from '../../core/items.component';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['../../core/items.component.scss']
})
export class UsersComponent extends ItemsComponent {

  name: string;
  picture: string;
  creating = false;
  disabled = false;

  options = { 
    filename: 'Movent - Usuarios',
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true, 
    showTitle: true,
    title: 'Movent - Usuarios',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    headers: ['Apellido', 'Nombre', 'Dni', 'Genero',
       'Km Recorridos', 'Email', 'Dirección', 'Teléfono',
       'Fecha de nacimiento', 'Talle de remera', 'Es celiaco',
       'Nombre de Contacto de Emergencia', 'Teléfono de Contacto de Emergencia',
       'Habilitado'
       ]
  };

  getEndpoint(){
    return this.settings.endPoints.organizators;
  }

  handleEnable(user) {
    user.enabled = !user.enabled;
    const enabledAction = (user.enabled ? 'habilitado' : 'deshabilitado');

    this.pageService.httpUpdate(user)
      .then(res => {
        this.pageService.showSuccess('El usuario ' + user.firstName + ' ' + user.lastName + ' ha sido ' + enabledAction + ' correctamente!')
      })
      .catch(err => this.pageService.showError(err));
  }

  getFilters() {
    let _filters = {};

    return _filters;
  }

  getFiltersSearch(textSearch) {
    let filtersSearch: any = {
      "$or": [
        { "firstName": { "$regex": textSearch, "$options": "i" } },
        { "lastName": { "$regex": textSearch, "$options": "i" } },
        { "emailAddress": { "$regex": textSearch, "$options": "i" } },
      ]
    };

    return filtersSearch;
  }

  goEdit(item: any){
    this.pageService.navigateRoute('/users/edit/' + item.id)
  }

  goWatch(item: any){
    this.pageService.navigateRoute('/users/watch/' + item.id)
  }

  itemsFormatted() {

    return this.items.map((item) => {
      return {
        Nombre: item.firstName || '-',
        Apellido: item.lastName || '-',
        Telefono: item.phoneNumber || '-',
        Genero: item.gender == 'male' ? 'Masculino' : 'Femenino' || '-',
        Email: item.emailAddress || '-',
        Dni: item.dni || '-',
        FechaDeNacimiento: item.birthDate || '-',
        KilometrosRecorridos: item.kilometersTraveled || '-',
        TalleDeRemera: item.tShirtSize || '-',
        EsCeliaco: item.isCeliac ? 'Si' : 'No' || '-',
        NombreContactoEmergencia: item.emergencyContactName || '-',
        TelefonoContactoEmergencia: item.emergencyContactPhoneNumber || '-',
        Habilitado: item.enabled ? 'Si' : 'No' || '-',
        Direccion: item.address || '-',
        Ciudad: item.locality || '',
        Provincia: item.province || '',
        Pais: item.country || ''
      }
    });
  }

  export() {
    const csvExporter = new ExportToCsv(this.options);

    csvExporter.generateCsv(this.itemsFormatted());
  }
}
