<div class="container">
  <nav aria-label="breadcrumb" class="mt-2">
    <ol class="breadcrumb">
      <li *ngIf="!isUserRoute" class="breadcrumb-item">
        <a [routerLink]="['/organizators']" routerLinkActive="router-link-active">Organizadores</a>
      </li>
      <li *ngIf="isUserRoute" class="breadcrumb-item">
        <a [routerLink]="['/users']" routerLinkActive="router-link-active">Usuarios</a>
      </li>
      <li *ngIf="!isUserRoute"class="breadcrumb-item active" aria-current="page">Modificar organizador</li>
      <li *ngIf="isUserRoute" class="breadcrumb-item active" aria-current="page">Modificar usuario</li>
    </ol>
  </nav>

  <form [formGroup]="form" (ngSubmit)="onSubmitPre()" class="needs-validation">

    <div class="form-row">
      <div class="col-md-6 mt-4">
        <fieldset>

          <legend>Datos personales</legend>

          <div class="card mb-3">
            <div class="row p-2 align-items-center">
              <div class="col-md-4">
                <img class="img-fluid img-thumbnail"
                  onError="this.src='../../../assets/imgs/Default_Image_Thumbnail.png'"
                  [src]="filesUrl + form?.value?.picture" />
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Foto de perfíl </h5>
                  <div class="" *ngIf="!isWatching">
                    <button type="button" class="btn btn-outline-primary text-center" (click)="changeImage()"><i
                        class="fa fa-cloud-upload" aria-hidden="true"></i> Subir imagen</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Nombre</label>
            <input type="text" class="form-control" [ngClass]="{'is-invalid': !isFieldValid('firstName')}"
              formControlName="firstName" [readonly]="isWatching" placeholder="Juan">
            <small *ngIf="!isFieldValid('firstName')" class="form-text text-danger">
              {{getFieldError('firstName')}}
            </small>
          </div>

          <div class="form-group">
            <label>Apellido</label>
            <input type="text" class="form-control" [ngClass]="{'is-invalid': !isFieldValid('lastName')}"
              formControlName="lastName" [readonly]="isWatching" placeholder="Perez">
            <small *ngIf="!isFieldValid('lastName')" class="form-text text-danger">
              {{getFieldError('lastName')}}
            </small>
          </div>

          <div class="form-group">
            <label for="validationCustom04" class="form-label">Sexo</label>
            <select class="form-control" id="validationCustom04" formControlName="gender" *ngIf="!isWatching">
              <option value="male">Masculino</option>
              <option value="female">Femenino</option>
            </select>
            <select class="form-control" id="validationCustom04" formControlName="gender" *ngIf="isWatching" disabled>
              <option value="male">Masculino</option>
              <option value="female">Femenino</option>
            </select>
          </div>

          <div class="form-group">
            <label>Fecha de nacimiento</label>
            <input id="datePicker" type="date" class="form-control" [readonly]="isWatching" formControlName="birthDate"
            [(ngModel)]="date" (change)="onChangeDate()">
          </div>

          <div class="form-group">
            <label>DNI</label>
            <input type="number" class="form-control" formControlName="dni" [readonly]="isWatching"
              placeholder="39185654">
            <small *ngIf="!isFieldValid('dni')" class="form-text text-danger">
              {{getFieldError('dni')}}
            </small>
          </div>

          <div class="form-group">
            <label>Teléfono</label>
            <input type="number" class="form-control" formControlName="phoneNumber" [readonly]="isWatching"
              placeholder="333-4789-569">
            <small *ngIf="!isFieldValid('phoneNumber')" class="form-text text-danger">
              {{getFieldError('phoneNumber')}}
            </small>
          </div>

          <div class="form-group">
            <label>E-Mail</label>
            <input type="email" class="form-control" formControlName="emailAddress" [readonly]="isWatching"
              placeholder="juan.perez@gmail.com">
            <small *ngIf="!isFieldValid('emailAddress')" class="form-text text-danger">
              {{getFieldError('emailAddress')}}
            </small>
          </div>

          <div class="form-group">
            <label>Kilometros recorridos</label>
            <input type="number" class="form-control" formControlName="kilometersTraveled" [readonly]="isWatching"
              placeholder="500">
            <small *ngIf="!isFieldValid('kilometersTraveled')" class="form-text text-danger">
              {{getFieldError('kilometersTraveled')}}
            </small>
          </div>

          <div class="form-group">
            <label for="validationCustom04" class="form-label">Talle de remera</label>
            <select class="form-control" id="validationCustom04" formControlName="tShirtSize" *ngIf="!isWatching">
              <option *ngFor="let tShirtSize of settings.tShirtSizes" [selected]="tShirtSize == item?.tShirtSize">{{tShirtSize}}</option>
            </select>
            <select class="form-control" id="validationCustom04" formControlName="tShirtSize" *ngIf="isWatching" disabled>
              <option *ngFor="let tShirtSize of settings.tShirtSizes" [selected]="tShirtSize == item?.tShirtSize">{{tShirtSize}}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="validationCustom04" class="form-label">Es celiaco</label>
            <select class="form-control" id="validationCustom04" (change)="onChangeIsCeliac($event)" *ngIf="!isWatching">
              <option value="no" [selected]="(isCeliac ? true : false)">No</option>
              <option value="si" [selected]="(isCeliac ? true : false)">Si</option>
            </select>
            <select class="form-control" id="validationCustom04" (change)="onChangeIsCeliac($event)" *ngIf="isWatching" disabled>
              <option value="no" [selected]="(isCeliac ? true : false)" selected>No</option>
              <option value="si" [selected]="(isCeliac ? true : false)" >Si</option>
            </select>
            <small *ngIf="!isFieldValid('isCeliac')" class="form-text text-danger">
              {{getFieldError('isCeliac')}}
            </small>
          </div>

          <div class="form-group">
            <label for="validationCustom04" class="form-label">Es un organizador</label>
            <select class="form-control" id="validationCustom04" (change)="onChangeIsOrganizator($event)" *ngIf="!isWatching">
              <option value="" disabled> --Seleccione una opción-- </option>
              <option value="si" [selected]="(isOrganizator ? true : false)"> Si </option>
              <option value="no" [selected]="(!isOrganizator ? true : false)"> No </option>
            </select>
            <select class="form-control" id="validationCustom04" (change)="onChangeIsOrganizator($event)" *ngIf="isWatching" disabled>
              <option value="" disabled> --Seleccione una opción-- </option>
              <option value="si" [selected]="(isOrganizator ? true : false)"> Si </option>
              <option value="no" [selected]="(!isOrganizator ? true : false)"> No </option>
            </select>
          </div>

          <div class="form-group" *ngIf="form.value.roles.includes('organizator')">
            <label>Porcentaje de comisión</label>
            <input type="number" min="0" max="100" class="form-control" formControlName="commissionPercentage" [readonly]="isWatching"
              placeholder="25%">
            <small *ngIf="(form.value.commissionPercentage === null || form.value.commissionPercentage < 0 || form.value.commissionPercentage > 100)" class="form-text text-danger">
              Ingrese un porcentaje de comisión válido (entre 1 y 99)
            </small>
          </div>

        </fieldset>
      </div>

      <div class="col-md-6 mt-4">
        <fieldset>
          <legend>Dirección</legend>

          <div class="form-group">
            <app-map [latitude]="latitude" [longitude]="longitude" [address]="address" [addressGoogle]="addressGoogle"
              (newAddress)="setNewAddress($event)" (newAddressGoogle)="setNewAddressGoogle($event)"
              (newLatitude)="setNewLatitude($event)" (newLongitude)="setNewLongitude($event)" [edit]="true"
              [isWatching]=isWatching>
            </app-map>
          </div>

          <div class="card">
            <h5 class="card-header">Contacto de emergencia</h5>
            <div class="card-body">
              <div class="form-group">
                <label>Nombre</label>
                <input type="text" class="form-control" [ngClass]="{'is-invalid': !isFieldValid('firstName')}"
                  formControlName="emergencyContactName" [readonly]="isWatching" placeholder="María">
                <small *ngIf="!isFieldValid('emergencyContactName')" class="form-text text-danger">
                  {{getFieldError('emergencyContactName')}}
                </small>
              </div>

              <div class="form-group">
                <label>Telefono</label>
                <input type="number" class="form-control" formControlName="emergencyContactPhoneNumber" [readonly]="isWatching"
                  placeholder="333-4789-569">
                <small *ngIf="!isFieldValid('emergencyContactPhoneNumber')" class="form-text text-danger">
                  {{getFieldError('emergencyContactPhoneNumber')}}
                </small>
              </div>
            </div>
          </div>

          <div class="card mb-3 mt-3">
            <div class="card-header">
              Tipos de carreras
              <button class="btn btn-primary float-right" type="button" *ngIf="!isWatching"
                (click)="openModal(categoryModal, null, null, '1')"><i class="fa fa-plus"></i></button>
            </div>
            <small *ngIf="!isFieldValid('emergencyContactPhoneNumber')" class="form-text text-danger">
              {{getFieldError('emergencyContactPhoneNumber')}}
            </small>

            <div class="card-body">
              <h5 class="card-title text-left">Nombre</h5>
              <hr>
              <ul class="list-group list-group-flush" disabled>
                <li class="list-group-item bg-transparent" *ngFor="let a of selectedCategories">
                  <p class="float-left">{{ a.name }}</p>
                  <button class="btn btn-link btn-sm float-right" type="button" *ngIf="!isWatching" (click)="removeCategory(a)">
                    <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>

        </fieldset>
      </div>

    </div>

    <hr>
  
    <div class="row mb-5" *ngIf="!creating && !isWatching">
      <div class="col-md-12">
        <button class="btn btn-info " type="button" (click)="recoveryPassword()">
          <i class="fa fa-lock" aria-hidden="true"></i>
          Restablecer contraseña
        </button>

        <button class="btn btn-primary float-right" type="submit">
          <i class="fa fa-check" aria-hidden="true"></i> GUARDAR
        </button>
      </div>
    </div>

  </form>

  <ng-template #categoryModal let-modal>

    <div class="modal-header">
      <h4 class="modal-title m-0" id="modal-basic-title" *ngIf="!disabled">{{ creating ? 'Tipo de eventos' : 'Editar
        categoría' }}</h4>
      <h4 class="modal-title m-0" id="modal-basic-title" *ngIf="disabled">Ver categoría</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>


    <div class="modal-body mt-2" style="height: auto;">

      <h5 class="card-title">Indique las opciones</h5>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let category of categories">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" name="category" [value]="category.id" (change)="onChangeCheck($event, category)" [checked]="selectedCategories.includes(category)">
            <label class="form-check-label" for="category">
              {{category.name}}
            </label>
          </div>
        </li>      
      </ul>
    </div>

    <div class="modal-footer" *ngIf="!disabled">
      <button type="button" class="btn btn-success" (click)="saveItemModal()"><mat-icon>save</mat-icon> Guardar</button>
    </div>
  </ng-template>

</div>